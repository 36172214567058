import { useState,useEffect } from 'react';

export default function Loading(){
    return (
        <div className="load-opacity show-s">
            <div className='yusufeli-load'>
                <img src='/assets/media/logo/yusufeli_load.gif'/>
            </div>
        </div>
    );
}