import { useState, useEffect } from 'react';
import { Link,useLocation } from "react-router-dom";
import InstitutionalMenu from '../../components/institutionalMenu';
import President from '../../components/president';
import { parliamentaryMemberList } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';


export default function ParliamentaryMember() {
    const [isLoading, setLoading] = useState(true);
    const [parliamentaryMemberLists, setParliamentaryMemberLists] = useState([]);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;

    useEffect(() => {
        parliamentaryMemberList(1, 100).then((response) => {
            setParliamentaryMemberLists(response.data);
            setLoading(false);
            console.log(response.data)
        })
    }, []);

    return (
        <div>
            <BreadCrump/>
            <div className="container-fluid bg-light">
                <div className="container py-5">
                <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu/>
                            <President/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <div className='row'>
                            {
                                isLoading == true ?
                                <LoadSpinner/> :
                                parliamentaryMemberLists.length != 0 ?
                                parliamentaryMemberLists.map((parliamentaryMember) => {
                                    return parliamentaryMember.ParliamentaryMemberWebApiViewModel.map((partyItem)=>{
                                        return (
                                            <div key={partyItem.Id} className="col-md-12 col-lg-6 col-xl-4">
                                                <Link className='text-dark text-decoration-none' to={activeUrl + '/' + partyItem.Id}>
                                                <div className="card border-0 px-2 mb-3 bg-transparent">
                                                        <img src={partyItem.MediaUrl != null ? partyItem.MediaUrl : '/assets/media/background/default-image.jpeg'} className="card-img-top rounded-15" alt="..." />
                                                        <div className="card-body text-center bg-opacity-100">
                                                            <h6 className="card-title">{partyItem.MemberNameSurname}</h6>
                                                            <p className='text-secondary'>{partyItem.PartyName}</p>
                                                            <small className='text-secondary'>{partyItem.MemberJobName}</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }): <InfoCard/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}