import { useState,useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AccessMenu from '../../components/accessMenu';
import BreadCrump from '../../components/breadCrump';
import InfoCard from '../../components/infoCard';
import LoadSpinner from '../../components/loadSpinner';
import President from '../../components/president';
import { projectList } from "../../services/services";

export default function Project(){
    const [projectsAllList,setProjectsAllList] = useState([]); 
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;

    useEffect(() => {
        projectList('1','1000').then((response)=>{
        setProjectsAllList(response.data);
        setLoading(false);
    })},[]);
 

return (
    <div>
       <BreadCrump/>
    <div className="container-fluid bg-light">
        <div className="container py-5">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                    <AccessMenu/>
                    <President/>
                </div>
                <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                    <div className="row">
                    {
                    isLoading == true ?
                    <LoadSpinner/> : 
                    projectsAllList.length != 0 ?
                    projectsAllList.map((projectsList)=>{
                        return (
                            <div key={projectsList.Id} className="col-md-12 col-lg-6 col-xl-4 mb-3">
                            <Link to={activeUrl + '/' + projectsList.Id} className="text-dark text-decoration-none">
                                <div className="card custom-cards border-0 px-2 mb-3 bg-transparent">
                                    <img src={projectsList.MediaURL} className="card-img-top rounded-15"
                                        alt="..."/>
                                    <div className="card-body bg-opacity-100">
                                        <h6 className="card-title custom-card-title">{projectsList.Title.length > 40 ? projectsList.Title.substring(0,40) + '...' : projectsList.Title}</h6>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        );
                    }):
                    <InfoCard/>
                    }  
                    </div>
                </div>  
            </div>    
        </div>
    </div>
    </div>
)
}