import { useState, useEffect } from 'react';
import AccessMenu from '../../components/accessMenu';
import President from '../../components/president';
import { eBookList } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';



export default function EBookList() {
    const [isLoading, setLoading] = useState(true);
    const [eBooks, setEBooks] = useState([]);
    useEffect(() => {
        eBookList('1', '1000').then((response) => {
            setEBooks(response.data);
            setLoading(false);
        });
    }, []);

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <AccessMenu/>
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <div className='row'>
                                {
                                    isLoading == true ?
                                        <LoadSpinner /> :
                                        eBooks.length != 0 ?
                                        eBooks.map((book, index) => {
                                                return (
                                                    <div key={book.Id} className="col-md-12 col-lg-6 col-xl-4">
                                                        <a className='text-dark text-decoration-none' target='_blank' href={book.FileUrl}>
                                                            <div className="card border-0 px-2 mb-3 bg-transparent">
                                                                <img src={book.MediaUrl} className="card-img-top-book rounded-15" alt="..." />
                                                                <div className="card-body bg-opacity-100 text-center">
                                                                    <h6 className="card-title">{book.Title}</h6>
                                                                    <small className="card-text opacity-75">-</small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            }) : <InfoCard />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}