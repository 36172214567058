import { useState,useEffect,useContext } from 'react';
import { Link,useLocation } from 'react-router-dom';
import { menuAll } from '../services/services';
import InfoCard from './infoCard';
import LoadSpinner from './loadSpinner';
import { LangContext,langs } from '../locales/langContext';


export default function InstitutionalMenu(){
    const {language, setLanguage} = useContext(LangContext);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    var activeUrlSplit = activeUrl.split('/');
    var moduleUrlName = '/' + activeUrlSplit[1];

    const [institutionalMenus,setInstitutionalMenus] = useState([]); 
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
      menuAll().then((response)=>{
        if(moduleUrlName != '/hizli-erisim'){
            //sonra bakılacak
            response.data.map((menu,index)=>{
                 //console.log(menu)
                menu.WebPartialMenuViewModel.map((subMenu)=>{
                    if(subMenu.child != 0){
                        if(subMenu.WebMenu.PrefixRoute == moduleUrlName){
                            setInstitutionalMenus(subMenu.child);
                            setLoading(false);
                        }
                    }
  
                })
                
            })
            //yukarısı çalışmazsa aşağıyı aç
            /*response.data[2].WebPartialMenuViewModel.map((topMenu,index)=>{
                if(topMenu.WebMenu.PrefixRoute == moduleUrlName){
                    console.log(response.data[2].WebPartialMenuViewModel[index].child)

                  setInstitutionalMenus(response.data[2].WebPartialMenuViewModel[index].child);
                  setLoading(false);
                }
            })*/
          }

          else{
            response.data[1].WebPartialMenuViewModel.map((topMenu,index)=>{
                if(topMenu.WebMenu.PrefixRoute == moduleUrlName){
                  setInstitutionalMenus(response.data[1].WebPartialMenuViewModel[index].child);
                  setLoading(false);
                }
            })
          }

    })},[]);

    return (
        <div className="card-side p-5">
                        <h4 className="fw-bold pb-3">{language.menu}</h4>
                        <div className="list-group list-group-flush">
                        {
                        isLoading == true ?
                        <LoadSpinner /> :
                        institutionalMenus.length != 0 ?
                        institutionalMenus.map((menus)=>{
                            var cActive = "";
                            var menuRoute = menus.WebMenu.PrefixRoute + menus.WebMenu.Route
                            if(activeUrl == menuRoute){
                                cActive = "c-active disabled";
                            }
                            else{
                                cActive = "bg-transparent";
                            }
                            if(menus.WebMenu.TargetBlank == true){
                                return ( 
                                    <a key={menus.Id} href={menus.WebMenu.Route} target='_blank' className={`list-group-item list-group-item-action bg-transparent py-3`}>{menus.WebMenu.Text}</a>
                                );
                            }
                            else{
                                return (
                                    <Link key={menus.Id} to={menuRoute} className={`list-group-item list-group-item-action py-3 ${cActive}`}>{menus.WebMenu.Text}</Link>
                                );
                            }
                        }): ''}
                          </div>
        </div>
    );
}