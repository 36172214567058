import { useState, useEffect } from 'react';
import InstitutionalMenu from '../../components/institutionalMenu';
import President from '../../components/president';
import { formerPresidentList } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';
import { Link,useLocation } from 'react-router-dom';


export default function FormerPresident() {
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const [isLoading, setLoading] = useState(true);
    const [formerPresidents, setFormerPresidents] = useState([]);
    useEffect(() => {
        formerPresidentList(1, 100).then((response) => {
            setFormerPresidents(response.data);
            setLoading(false);
        })
    }, []);

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <div className='row'>
                                {
                                    isLoading == true ?
                                        <LoadSpinner /> :
                                        formerPresidents.length != 0 ?
                                            formerPresidents.map((formerP, index) => {
                                                return (
                                                    <div key={formerP.Id} className="col-md-12 col-lg-6 col-xl-4">
                                                        <Link className='text-dark text-decoration-none' to={`/kurumsal/eski-baskanlar/${formerP.Id}`}>
                                                            <div className="card border-0 px-2 mb-3 bg-transparent">
                                                                <img src={formerP.MediaURL != null ? formerP.MediaURL : '/assets/media/background/default-image.jpeg'} className="card-img-top rounded-15" alt="..." />
                                                                <div className="card-body bg-opacity-100 text-center">
                                                                    <h6 className="card-title">{formerP.Title}</h6>
                                                                    <small className="card-text opacity-75">{formerP.StartDate_String} - {formerP.EndDate_String}</small>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }) : <InfoCard />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}