import { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser';
import InstitutionalMenu from '../../components/institutionalMenu';
import BreadCrump from '../../components/breadCrump';
import President from '../../components/president';
import { parliamentaryAgendaList } from "../../services/services";
import InfoCard from '../../components/infoCard';
import LoadSpinner from '../../components/loadSpinner';

export default function ParliamentaryAgendaDetail() {
    const [isLoading, setLoading] = useState(true);
    const [parliamentaryAgendaLists, setParliamentaryAgendaLists] = useState([]);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    var parliamentaryId = activeUrl.split('/')[3];

    useEffect(() => {
        parliamentaryAgendaList(1, 100,0).then((response) => {
            setParliamentaryAgendaLists(response.data);
            setLoading(false);
        })
    }, []);

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            {
                                isLoading == true ?
                                <LoadSpinner/> :
                                parliamentaryAgendaLists.length != 0 ?
                                    parliamentaryAgendaLists.map((parliamentary) => {
                                        if(parseInt(parliamentary.Id) == parseInt(parliamentaryId)){
                                            return (
                                                <div key={parliamentary.Id}> 
                                                    <h2 className="my-3">{parliamentary.Title}</h2>
                                                    { ReactHtmlParser (parliamentary.HtmlContent) }
                                                </div>
                                            )
                                        }
                                    })
                                    : <InfoCard />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}