import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import AccessMenu from '../../components/accessMenu';
import President from '../../components/president';
import { travelDetails } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';
import FsLightbox from 'fslightbox-react';
import VideoThumbnail from 'react-video-thumbnail'; 
import InstitutionalMenu from '../../components/institutionalMenu';



export default function TravelDetail() {
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const urlId = activeUrl.split("/")[3];
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    //Carousel 
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 }
    ];

    const [travelsDetails, setTravelsDetails] = useState([]);
    useEffect(() => {
        travelDetails(urlId).then((response) => {
            setTravelsDetails(response.data);
            //console.log(response.data)
            setLoading(false);
        })
    }, []);

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    const eMailCopy = () => {
       // Create a "hidden" input
  var aux = document.createElement("input");

  // Assign it the value of the specified element
  aux.setAttribute("value", document.getElementById('e-mail-copy').innerHTML);

  // Append it to the body
  document.body.appendChild(aux);

  // Highlight its content
  aux.select();

  // Copy the highlighted text
  document.execCommand("copy");

  // Remove it from the body
  document.body.removeChild(aux);
    }


    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">

                            {
                                isLoading == true ?
                                    <LoadSpinner /> :
                                    travelsDetails.length != 0 ?
                                        <div>
                                            {
                                                travelsDetails.Category.map((detailCategories) => {
                                                    return (<span key={detailCategories.CategoriesID} className="c-badge text-dark text-start me-1">{detailCategories}</span>)
                                                })}
                                            <small className="card-text opacity-75 ms-3">{travelsDetails.NearDateLocal}</small>
                                            <h2 className="my-3">{travelsDetails.Header}</h2>
                                            <img src={travelsDetails.MediaUrl != null ? travelsDetails.MediaUrl.replace('/original/', '/780/') : '/assets/media/background/default-image.jpeg'} className="detail-img rounded-15 mb-4" alt="..." />
                                            <p className='fw-bold'>{travelsDetails.Summary}</p>
                                            <p>{travelsDetails.Detail != null ? travelsDetails.Detail.split("\n").map((line, idx) => <p key={idx}>{line}</p>) : ''}</p>
                                            <div className="card-side text-center mt-4 p-5">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                        <p className="opacity-50">İşletme Adı</p>
                                                        <p className="fw-bolder">{travelsDetails.Arranger}</p>
                                                    </div>
                                                    {
                                                        travelsDetails.ShowPrice == true ?
                                                            <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                                <p className="opacity-50">Bilet Fiyatı</p>
                                                                <p className="fw-bolder">{travelsDetails.Price}</p>
                                                            </div> : ''
                                                    }
                                                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                        <p className="opacity-50">Katılım Koşulu</p>
                                                        <p className="fw-bolder">{travelsDetails.Condition}</p>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                        <p className="opacity-50">GSM</p>
                                                        <p className="fw-bolder">{travelsDetails.ContactGsmNumber}</p>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                        <p className="opacity-50">Telefon</p>
                                                        <p className="fw-bolder">{travelsDetails.ContactPhoneNumber}</p>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                        <p className="opacity-50">E-Posta</p>
                                                        <p className='d-none' id='e-mail-copy'>{travelsDetails.ContactEmailAddress}</p>
                                                        <p className="fw-bolder" data-bs-toggle="tooltip" data-bs-placement="top" title={travelsDetails.ContactEmailAddress}>{travelsDetails.ContactEmailAddress.length > 25 ? travelsDetails.ContactEmailAddress.substring(0, 25) + '...' : travelsDetails.ContactEmailAddress}</p>
                                                        <button className='btn' onClick={eMailCopy}><i class="far fa-clone"></i></button>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                        <p className="opacity-50">Adres</p>
                                                        <p className="fw-bolder">{travelsDetails.Address}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                travelsDetails.OtherMedias != 0 ?
                                                    <div>
                                                        <Carousel itemsToShow={3} breakPoints={breakPoints} className='multi-carosuel mt-5'>
                                                            {
                                                                travelsDetails.OtherMedias.map((mediasUrls, index) => {
                                                                    //console.log(mediasUrls.split('.')[3])
                                                                    var mediaType = mediasUrls.split('.')[3];
                                                                    var mediaDefault = ""
                                                                    if(mediaType == "png" || mediaType == "jpeg" || mediaType == "jpg"){
                                                                        mediaDefault = mediasUrls.replace('/original/', '/300/');
                                                                    }
                                                                    else{
                                                                        mediaDefault = "/assets/media/background/default-video.png"
                                                                    }
                                                                    return (
                                                                        <div onClick={() => openLightboxOnSlide(index + 1)} key={`media-${index}`} className="card bg-transparent border-0 px-2">
                                                                            <img className="img-fluid card-img-top-media" src={mediaDefault} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Carousel>
                                                        <FsLightbox
                                                            toggler={lightboxController.toggler}
                                                            sources={travelsDetails.OtherMedias}
                                                            slide={lightboxController.slide}
                                                        />
                                                    </div> : ''
                                            }
                                        </div> : <InfoCard />
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}