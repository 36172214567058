import { useState, useEffect,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SideMenu from '../components/sideMenu';
import { menuAll, socialMedia, searchList } from '../services/services'
import LoadSpinner from '../components/loadSpinner';
import Loading from '../components/loading';
import InfoCard from '../components/infoCard';
import { detailTextControl } from '../utils/utils';
import { LangContext, langs } from '../locales/langContext';


export default function Header() {
  
    const {language, setLanguage} = useContext(LangContext);
    const selectedLangu = localStorage.getItem('LANGU-OS-91');
    const [allMenu, setAllMenu] = useState([]);
    const [socialMedias, setSocialMedias] = useState([]);
    const [searchAll, setSearchAll] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
   // console.log("Dil ", selectedLangu + " "+ language.langu);



    useEffect(() => {
        menuAll(language.languNumber).then((response) => {
            setAllMenu(response.data[2].WebPartialMenuViewModel);
            setLoading(false);
        })
    }, [language]);

    useEffect(() => {
        socialMedia().then((response) => {
            setSocialMedias(response.data);
        })
    }, []);

    const searchReset = () => {
        setSearchAll('');
        reset();
    }

    const onSubmit = data => {
        setSearchLoading(true)
        searchList(data.SearchText,1,1000).then((response) => {
            setSearchAll(response.data);
            setSearchLoading(false)
        })
    }

    const handleChangeLanguage = (e) =>{
        var selectedLanguage = e.target.value;
            if(selectedLanguage == "tr"){
                localStorage.setItem('LANGU-OS-91', "tr");
                setLanguage(langs.tr);
            }
            else if(selectedLanguage == "en"){
                localStorage.setItem('LANGU-OS-91', "en");
                setLanguage(langs.en)
            }
            else{
                localStorage.setItem('LANGU-OS-91', "tr");
                setLanguage(langs.tr)
            }

    }


    

    return (
        <div>
            {
                isLoading == true ?
                    <Loading /> : ''
            }

            <div className="bg-opacity"></div>
            <div className="top-top">
                <nav className="py-1 top-nav">
                    {
                        socialMedias.length != 0 ?
                            <div className="container d-flex flex-wrap">
                                <ul className="nav me-auto">
                                    {
                                        socialMedias.AppSiteSocialMediaAccounts.map((social) => {
                                            if (social.IconUrl.split('/')[5] == 'material-live-tv.png') {
                                                return (
                                                    <li key={social.Id} className="nav-item">
                                                        <a href={social.SocialUrl} target="_blank" className="nav-link link-light" aria-current="page">
                                                            <img width='18' src={social.IconUrl} />
                                                        </a>
                                                    </li>
                                                )

                                            }
                                            else {
                                                return (
                                                    <li key={social.Id} className="nav-item">
                                                        <a href={social.SocialUrl} target="_blank" className="nav-link link-light" aria-current="page">
                                                            <i className={social.IconUrl}></i>
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                                <ul className="nav">
                                    <li className="nav-item"><a href={`tel:${socialMedias.ContactInformation.Phone}`} className="nav-link link-light px-2"><img
                                        src="/assets/media/icons/phone.svg" className="px-2" />{socialMedias.ContactInformation.Phone}</a></li>
                                    <li className="nav-item"><a href={`mailto:${socialMedias.ContactInformation.Email}`} className="nav-link link-light px-2"><img
                                        src="/assets/media/icons/mail.svg" className="px-2" />{socialMedias.ContactInformation.Email}</a></li>
                                        <li className="nav-item hide-h">
                                            <select value={selectedLangu} className="form-select form-select-sm border-0 ms-3 my-1" style={{width:"65px"}} onChange={handleChangeLanguage} aria-label="Default select example">
                                                <option value="tr">TR</option>
                                                <option value="en">EN</option>
                                                <option value="ru">RU</option>
                                            </select>
                                        </li>
                                </ul>
                            </div> : ''
                    }
                </nav>
                <div className="m-header">
                    <div className="container">
                        <header className="d-flex flex-wrap align-items-center justify-content-between py-2">
                            <Link to="/" className="d-flex align-items-center col-xl-1 mb-2 text-dark text-decoration-none">
                                <img src="/assets/media/logo/yusufeli_logo.svg" />
                            </Link>
                            <ul className="nav nav-menu col-10 col-md-auto justify-content-center">
                                {
                                    isLoading == true ?
                                        <LoadSpinner /> :
                                        allMenu.map((webMenu) => {
                                            var dropDownVisible = "";
                                            var dropdowns = "dropdown";
                                            if (webMenu.child == "") {
                                                dropDownVisible = "hide-h hide";
                                                dropdowns = "";
                                            }
                                            return (
                                                <li key={webMenu.Id} className={`nav-item ${dropdowns}`}>
                                                    <Link className="nav-link link-light" to={webMenu.WebMenu.PrefixRoute} role="button"
                                                        data-bs-toggle={dropdowns} aria-expanded="false">
                                                        {webMenu.WebMenu.Text}
                                                    </Link>
                                                    <ul className={`dropdown-menu border-0 ${dropDownVisible}`}>
                                                        {
                                                            webMenu.child.map((subMenu) => {
                                                                if (subMenu.WebMenu.TargetBlank == true) {
                                                                    return (
                                                                        <li key={subMenu.Id}><a className="dropdown-item" target='_blank' href={subMenu.WebMenu.Route}>{subMenu.WebMenu.Text}</a></li>
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <li key={subMenu.Id}><Link className="dropdown-item" to={subMenu.WebMenu.PrefixRoute + subMenu.WebMenu.Route}>{subMenu.WebMenu.Text}</Link></li>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </ul>
                                                </li>
                                            );
                                        })}
                            </ul>
                            <div className="col-6 col-lg-4 col-xl-2 text-end">
                            <button type="button" onClick={searchReset} data-bs-toggle="modal" data-bs-target="#generalSearch" className="btn me-2"><img src="/assets/media/icons/Search.svg" /></button>
                                <div className="btn"><img src="/assets/media/icons/line.svg" /></div>
                                <button type="button" className="btn side-menu-icon me-2"><img src="/assets/media/icons/menu.svg" /></button>
                            </div>
                        </header>
                    </div>
                </div>
                <div className="side-menu">
                    <button className="btn closed-side me-6 mt-4"><img src="/assets/media/icons/close.svg" width="32" /></button>
                    <SideMenu />
                </div>
            </div>
            <div className="modal fade" id="generalSearch" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Genel Arama</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-5" style={{ height: '40vh' }}>
                            <form onSubmit={handleSubmit(onSubmit)} className="row justify-content-center">
                                <div className="col-7 col-md-9 mt-2">
                                    <input className="form-control form-control-lg" onKeyPress={detailTextControl} {...register("SearchText", { required: true })} maxLength={150} type="text" placeholder="Ara" />
                                </div>
                                <div className="col-5 col-sm-4 col-md-3 mt-2">
                                    <button type="submit" className="btn btn-green btn-lg btn-block mb-3">Ara</button>
                                </div>
                            </form>
                            {
                                searchLoading == true ?
                                    <LoadSpinner /> :
                                    searchAll.length != 0 ?
                                        <div>
                                            <p className='fw-bold'>Bulunan Toplam Sonuç : {searchAll.length}</p>
                                            {
                                                searchAll.map((results) => {                                                    if(results.TargetBlank == 0){
                                                        return (
                                                            <Link to={results.Link} key={results.Link} className='text-decoration-none'>
                                                                <div className="alert alert-secondary" data-bs-dismiss="modal" role="alert">
                                                                    <span className="badge btn-green me-2">{results.MenuName}</span>{results.Title}
                                                                </div>
                                                            </Link>
                                                        )
                                                    }
                                                    else{
                                                        return (
                                                            <a href={results.Link} key={results.Link} target="_blank" className='text-decoration-none'>
                                                                <div className="alert alert-secondary" data-bs-dismiss="modal" role="alert">
                                                                    <span className="badge btn-green me-2">{results.MenuName}</span>{results.Title}
                                                                </div>
                                                            </a>
                                                        )
                                                    }
                                                })
                                            }
                                        </div> : <InfoCard/>
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}