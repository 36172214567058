import { useState, useEffect } from 'react';
import AccessMenu from '../../components/accessMenu';
import BreadCrump from '../../components/breadCrump';
import President from '../../components/president';


export default function WebTV() {

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <AccessMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <iframe width="100%" style={{ overflow: 'hidden', height: '50vh' }} src="../live.html" title="W3Schools Free Online Web Tutorials"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}