import { useState,useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AccessMenu from '../../components/accessMenu';
import BreadCrump from '../../components/breadCrump';
import InfoCard from '../../components/infoCard';
import InstitutionalMenu from '../../components/institutionalMenu';
import LoadSpinner from '../../components/loadSpinner';
import President from '../../components/president';
import { socialServiceList } from "../../services/services";


export default function SocialServices(){
    const [socialServiceLists,setSocialServiceLists] = useState([]); 
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const [pageCount,setPageCount] = useState(15);
    const [btnDisabled,setBtnDisabled] = useState(false);

    useEffect(() => {
        socialServiceList('1',pageCount).then((response)=>{
            setSocialServiceLists(response.data);
        setLoading(false);
        if(response.data.length != 0){
            if(response.data[0].TotalItem <= pageCount){
                setBtnDisabled(true);
            }
            else{
                setBtnDisabled(false);
            }
        }
        else{
            setBtnDisabled(true);
        }
    })},[]);

    const loadData = () => {
        var pageItem = pageCount + 9
        setPageCount(pageItem)
        socialServiceList('1',pageItem).then((response)=>{
            setSocialServiceLists(response.data);
            setLoading(false);
            if(socialServiceLists[0].TotalItem == response.data.length) {
                setBtnDisabled(true);
              }
              else {
                setBtnDisabled(false);
              }
        });
    }


return (
    <div>
       <BreadCrump/>
    <div className="container-fluid bg-light">
        <div className="container py-5">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                    <InstitutionalMenu/>
                    <President/>
                </div>
                <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                    <div className="row">
                    {
                    isLoading == true ?
                    <LoadSpinner/> : 
                    socialServiceLists.length != 0 ?
                    socialServiceLists.map((socialList)=>{
                        return (
                            <div key={socialList.Id} className="col-md-12 col-lg-6 col-xl-4 mb-3">
                            <Link to={activeUrl + '/' + socialList.Id} className="text-dark text-decoration-none">
                                <div className="card custom-cards border-0 px-2 mb-3 bg-transparent">
                                    <img src={socialList.MediaURL} className="card-img-top rounded-15"
                                        alt="..."/>
                                    <div className="card-body bg-opacity-100">
                                        <h6 className="card-title custom-card-title">{socialList.Header.length > 60 ? socialList.Header.substring(0,60) + '...' : socialList.Header}</h6>
                                        <div className="d-flex justify-content-between mt-">
                                            {socialList.Category.map((categories,index)=>{
                                                if(index < 1){
                                                    return (<span key={socialList.Id} className="c-badge text-dark text-start">{categories.length > 40 ? categories.substring(0,40) + '...' : categories}</span>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        );
                    }):
                    <InfoCard/>
                    }  
                    </div>
                    <div className='text-center'><button onClick={loadData} disabled={btnDisabled} className='btn btn-green p-3 px-4'>Daha Fazla Yükle</button></div>
                </div>  
            </div>    
        </div>
    </div>
    </div>
)
}