import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

    const toastOptions = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    }

    
// Name Text Control
export function nameControl(e) {

    var regex = new RegExp("^[A-Za-z\u00E7\u011F\u0131\u015F\u00F6\u00FC\u00C7\u011E\u0130\u015E\u00D6\u00DC ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
}

export function nameControlPaste(e){
  e.target.value = e.target.value.replace(/[^A-Za-z\u00E7\u011F\u0131\u015F\u00F6\u00FC\u00C7\u011E\u0130\u015E\u00D6\u00DC ]/g,'');
 if(!e.target.value){
   console.log("aa");
      
 } 
 else{
   console.log("bb")
 }
 
}

// General Text Control
export function detailTextControl(e) {
  var regex = new RegExp("^[^<>]| +$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if(e.target.value == " "){
    document.getElementById('TextMessage').value = "";
    e.preventDefault();
  }
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

// Phone Control
export function phoneControl(e) {
    var regex = new RegExp("^[0-9+ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
}

// Number Control
export function numberControl(e) {
  var regex = new RegExp("^[0-9]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

// Email Control
export function emailControl(e) {
    if (!e.target.value || e.target.value.length === 0) {
        return 'Email cannot be empty';
      }
    
      const isEmailValid = /@/.test(e.target.value); // use any validator you want
      if (!isEmailValid) {
        return 'Invalid email provided';
      }
    
      return null;
}

  //TC Number Control
  const checkTcNum = function (value) {
    value = value.toString();
    var isEleven = /^[0-9]{11}$/.test(value);
    var totalX = 0;
    for (var i = 0; i < 10; i++) {
      totalX += Number(value.substr(i, 1));
    }
    var isRuleX = totalX % 10 == value.substr(10, 1);
    var totalY1 = 0;
    var totalY2 = 0;
    var isZero = true;
    for (var i = 0; i < 10; i += 2) {
      totalY1 += Number(value.substr(i, 1));
    }
    for (var i = 1; i < 10; i += 2) {
      totalY2 += Number(value.substr(i, 1));
    }
    var isRuleY = ((totalY1 * 7) - totalY2) % 10 == value.substr(9, 0);
    if (value[0] == 0) {
      isZero = false;
    }
    return isEleven && isRuleX && isRuleY && isZero;
  };

  export function tcknControl(e) {
    var textVal = e.target.value;
    var tcValid = checkTcNum(textVal);
    if (!tcValid) {
        toast.error("Hatalı TCKN Formatı Girdiniz !", toastOptions);
        document.getElementById('TcNumbers').value = "";
    } else {
    }
}
