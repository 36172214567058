import { useState, useEffect,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { presidentDetails,menuAll } from '../services/services';
import LoadSpinner from './loadSpinner';
import { LangContext,langs } from '../locales/langContext';


export default function President() {
    const {language, setLanguage} = useContext(LangContext);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;

    const [presidentDetail, setPresidentDetail] = useState([]);
    const [allMenu, setAllMenu] = useState([]);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        presidentDetails().then((response) => {
            setPresidentDetail(response.data);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        menuAll().then((response) => {
            if(response != null){
                response.data.map((menu)=>{
                    if(menu.Name == "TopMenu"){
                        menu.WebPartialMenuViewModel.map((presidentMenu)=>{
                            if(presidentMenu.MenuId == 2){
                                setAllMenu(presidentMenu);
                                setLoading(false);
                            }
                        })   
                    }
                })
            }            
        })
    }, []);


    return (
        isLoading == true ?
            <LoadSpinner /> :
            presidentDetail.length != 0 ?
                <div className="card-side text-center mt-4 p-5">
                    <h4 className="fw-bolder pb-3 text-start">{language.presidentTitle}</h4>
                    <img className='img-fluid rounded-15 mb-3' src={
                        presidentDetail.Media[0] != null ?
                            presidentDetail.Media[0].MediaUrl : '/assets/media/background/default-image.jpeg'
                    } alt="..." />
                    <p className="mb-0">{presidentDetail.Name + ' ' + presidentDetail.LastName}</p>
                    <small className="opacity-50">{presidentDetail.Title}</small>
                    <div className="my-4">
                        {
                            presidentDetail.MayorSocialMediaAccountsViewModel.length != 0 ?
                                presidentDetail.MayorSocialMediaAccountsViewModel.map((socialMedia) => {
                                    return (
                                        <a key={socialMedia.IconUrl} target='_blank' href={socialMedia.SocialUrl} className="btn">
                                            <img src={`/assets/media/icons/${socialMedia.IconUrl}`} />
                                        </a>
                                    )
                                }) : ''
                        }
                    </div>
                    <div className="d-grid gap-3 mt-3">
                        {
                            allMenu.length != 0 ?
                            allMenu.child.map((preSubMenu)=>{
                               return (
                                <Link key={preSubMenu.Id} to={preSubMenu.WebMenu.PrefixRoute + preSubMenu.WebMenu.Route} className="btn btn-light p-3">{preSubMenu.WebMenu.Text}</Link>
                               )
                            }) : ""
                        }
                    </div>
                </div> : ''
    );
}