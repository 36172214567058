import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import BreadCrump from "../../components/breadCrump";
import InfoCard from "../../components/infoCard";
import InstitutionalMenu from "../../components/institutionalMenu";
import President from "../../components/president";
import { incomeExpenseBudgetList } from "../../services/services";
import LoadSpinner from "../../components/loadSpinner";

export default function IncomeExpenseBudget() {
  const [incomeExpenseBudgetLists, setIncomeExpenseBudgetLists] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const urlLocation = useLocation();
  const activeUrl = urlLocation.pathname;

  useEffect(() => {
    incomeExpenseBudgetList().then((response) => {
      setIncomeExpenseBudgetLists(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <BreadCrump />
      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5 col-xl-3">
              <InstitutionalMenu />
              <President />
            </div>
            <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
              {
                isLoading == true ?
                <LoadSpinner/> :
                incomeExpenseBudgetLists.length != 0 ?
                  incomeExpenseBudgetLists.map((incomeExpenseBudget, index) => {
                    return (
                      <div key={incomeExpenseBudget.Id} className="accordion" id="accordionExample">
                        <div className="accordion-item border-0 bg-transparent">
                          <h2 className="accordion-header" id="headingOne">
                            <button className="btn card-side c-hover text-start p-4 mb-3" type="button" data-bs-toggle="collapse" data-bs-target={`#accordions${incomeExpenseBudget.Id}`} aria-expanded="true" aria-controls={`accordions${incomeExpenseBudget.Id}`}>
                              <img width="32" src="/assets/media/icons/announcement.svg" />
                              <span className="fw-bolder ms-3">{incomeExpenseBudget.Title.substring(0, 100)}</span>
                            </button>
                          </h2>
                          <div id={`accordions${incomeExpenseBudget.Id}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <div className="row">
                                {
                                  incomeExpenseBudget.WebIncomeExpenditureBudgetDetail != null ?
                                  incomeExpenseBudget.WebIncomeExpenditureBudgetDetail.map((file) => {
                                    return (
                                      <div key={file.FileName} className="col-lg-12 text-start mb-2 c-hover p-3 rounded-15">
                                        <a href={file.FileUrl} target="_blank" className="text-dark text-decoration-none">
                                          <div>
                                          <img src="/assets/media/icons/file-download.svg" width="40" />
                                          <span className="ms-3">{file.FileName}</span>
                                          </div>
                                        </a>
                                      </div>
                                    )
                                  }) : ''
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    );
                  }) :
                  <InfoCard />
              }


            </div>
          </div>
        </div>
      </div>
    </div >
  );
}


