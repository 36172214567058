import { useState, useEffect,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LangContext,langs } from '../../locales/langContext';

export default function ErrorPage() {
    const {language, setLanguage} = useContext(LangContext);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;

    return (
        <div>
            <div className="container py-5">
                <h1 className="text-light fw-bold pt-5">{language.errorTitle}</h1>
                <p className="text-light opacity-50 pb-2">{language.home} / {language.error}</p>
            </div>
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="alert alert-danger text-center p-5" role="alert">
                        <img src='/assets/media/icons/error.svg' width='48'/>
                        <div className='mt-4'>
                            <h3 className='fw-bold'>{language.errorSubTitle}</h3>
                            <p>{language.errorDesc}</p>
                            <small>{language.errorDescReload}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}