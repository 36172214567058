import { useState, useEffect,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import President from '../../components/president';
import InfoCard from '../../components/infoCard';
import { useForm } from 'react-hook-form';
import InstitutionalMenu from '../../components/institutionalMenu';
import BreadCrump from '../../components/breadCrump';
import { presidentMessages } from '../../services/services';
import { ipAdress } from '../../services/services';
import { nameControl,phoneControl, detailTextControl } from '../../utils/utils';
import { LangContext,langs } from '../../locales/langContext';


export default function PresidentMessage() {
    const {language, setLanguage} = useContext(LangContext);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const [presidentMessa, setPresidentMessa] = useState([]);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const [ip, setIP] = useState();



    useEffect(() => {
        ipAdress().then((response)=>{
            setIP(response.data.IPv4);
        });
    }, []);

    const toastOptions = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined

    }

    const onSubmit = data => {
        const dataJson = JSON.stringify(data);
        
        presidentMessages(dataJson).then((response) => {
            setPresidentMessa(response.data);
            if (response.data.Success) {
                toast.success(response.data.Message, toastOptions);
                reset();
            }
            else {
                toast.error(response.data.Message, toastOptions)
            }

        })
    }

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <ToastContainer />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    {
                                        ip != null ? 
                                        <input type="hidden" value={ip} className="form-control p-3" placeholder='Ip Adres' {...register("IpAddress")} maxLength={50} />
                                        : ''
                                    }
                                        <input type="text" className="form-control p-3" placeholder={language.fullName} onKeyPress={nameControl} pattern=".*\S+.*"  {...register("Title", { required: true })} maxLength={250} />
                                </div>
                                <div className="mb-3">
                                        <input type="email" className="form-control text-c p-3" placeholder={language.email} onKeyPress={detailTextControl} pattern=".*\S+.*" {...register("Email", { required: true })} maxLength={256} />
                                        <div className="form-text e-mail-control-text text-danger d-none"></div>
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" className="form-control phone-c p-3" placeholder={language.phoneNumber} onKeyPress={phoneControl} pattern=".*\S+.*" {...register("PhoneNumber", { required: true })} maxLength={15} />
                                    </div>
                                    <div className="mb-3">
                                        <textarea className='form-control p-3 w-100' placeholder={language.messageText} rows='5' id='TextMessage' onKeyUp={detailTextControl} onKeyPress={detailTextControl} pattern=".*\S+.*" {...register("Detail", { required: true })}></textarea>
                                    </div>
                                    <div className="form-text mb-3">{language.requiredText}</div>
                                    <div className="form-text mb-3 fw-bold">{language.requiredInfo}</div>
                                    <button type="submit" className="btn btn-green p-3">{language.send}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}