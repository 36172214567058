import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter,Routes,Route,useLocation } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter basename='/'>
      <App />
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
