import { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import InstitutionalMenu from '../../components/institutionalMenu';
import President from '../../components/president';
import { formerPresidentList } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';


export default function FormerPresidentDetail() {
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const urlId = activeUrl.split('/')[3];
    const [isLoading, setLoading] = useState(true);
    const [formerPresidents, setFormerPresidents] = useState([]);
    const navigate = useNavigate()
    
    useEffect(() => {
        formerPresidentList(1, 100).then((response) => {
            response.data.map((res)=>{
                if(res.Id == parseInt(urlId)){
                    setFormerPresidents(response.data);
                }
                else{
                }
            })
            setLoading(false);
        })
    }, []);



    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <div className='row'>
                                {
                                    isLoading == true ?
                                        <LoadSpinner /> :
                                        formerPresidents.length != 0 ?
                                            formerPresidents.map((formerP, index) => {
                                                if (formerP.Id == parseInt(urlId)) {
                                                    return (
                                                        <div key={formerP.Id}>
                                                            <h2 className="my-3">{formerP.Title}</h2>
                                                            <p className='opacity-50'>{formerP.StartDate_String} - {formerP.EndDate_String}</p>
                                                            <img src={formerP.MediaURL != null ? formerP.MediaURL : '/assets/media/background/default-image.jpeg'} className="detail-img rounded-15 mb-4" alt="..." />
                                                            {ReactHtmlParser(formerP.HtmlContent)}
                                                        </div>
                                                    )
                                                }
                                                else{
                                                    return ''
                                                    //return navigate("/404")

                                                }

                                            }) : <InfoCard />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}