import { useState, useEffect } from 'react';
import InstitutionalMenu from '../../components/institutionalMenu';
import President from '../../components/president';
import { galleryList } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';
import { Link, useLocation } from 'react-router-dom';


export default function Gallery() {
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const [isLoading, setLoading] = useState(true);
    const [gallery, setGallery] = useState([]);
    useEffect(() => {
        galleryList().then((response) => {
            if (response.data.length != 0) {
                response.data.map((photos) => {
                    if (photos.PublishType == 1) {
                        setGallery(response.data);
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                    }
                })
            }
            else {
                setGallery('');
                setLoading(false);
            }
        })
    }, []);

    console.log(gallery)

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <div className='row'>
                                {
                                    isLoading == true ?
                                        <LoadSpinner /> :
                                        gallery.length != 0 ?
                                            gallery.map((album, index) => {
                                                if (album.PublishType == 1) {
                                                    return (
                                                        <div key={album.Id} className="col-md-12 col-lg-6 col-xl-4 mb-3">
                                                            <Link to={activeUrl + '/' + album.Id} className="text-dark text-decoration-none">
                                                                <div className="card custom-cards border-0 px-2 mb-3 bg-transparent">
                                                                    <img src={album.ImageUrl.replace('/original/', '/780/')} className="card-img-top rounded-15"
                                                                        alt="..." />
                                                                    <div className="card-body bg-opacity-100">
                                                                        <h6 className="card-title custom-card-title text-center">{album.Title.substring(0, 60)}</h6>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                            }) : <InfoCard />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}