import { useState, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
import InstitutionalMenu from '../../components/institutionalMenu';
import President from '../../components/president';
import { galleryList } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';
import { Link, useLocation } from 'react-router-dom';
import FsLightbox from 'fslightbox-react';


export default function PresidentGalleryDetail() {
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const imagesId = activeUrl.split('/')[3];
    const [isLoading, setLoading] = useState(true);
    const [gallery, setGallery] = useState([]);

    useEffect(() => {
        galleryList().then((response) => {
            response.data.map((images, index) => {  
                if (images.Id == parseInt(imagesId)) {
                    if(images.Images.length == 0){
                        setGallery('');
                        setLoading(false);
                    }
                    else{
                        setGallery(images);
                        setLoading(false);
                    }
                    
                }
                else{
                    //setGallery('');
                    setLoading(false);
                }
            })
        })
    }, []);

    //Carousel 
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 }
    ];

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <div className='row'>
                                {
                                    isLoading == true ?
                                        <LoadSpinner /> :
                                        gallery.length != 0 ?
                                            gallery.Images.map((album, index) => {
                                                return (
                                                    <div key={`photo-${index}`} className="col-md-12 col-lg-6 col-xl-4 mb-3">
                                                        <div onClick={() => openLightboxOnSlide(index + 1)} className="card custom-cards border-0 px-2 mb-3 bg-transparent">
                                                            <img src={album.replace('/original/', '/780/')} className="card-img-top rounded-15"
                                                                alt="..." />
                                                        </div>
                                                    </div>
                                                )
                                            }) : <InfoCard />}
                                <FsLightbox
                                    toggler={lightboxController.toggler}
                                    sources={gallery.Images}
                                    slide={lightboxController.slide}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}