import { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser';
import InstitutionalMenu from '../../components/institutionalMenu';
import BreadCrump from '../../components/breadCrump';
import President from '../../components/president';
import { parliamentaryMemberList } from "../../services/services";
import InfoCard from '../../components/infoCard';
import LoadSpinner from '../../components/loadSpinner';

export default function ParliamentaryMemberDetail() {
    const [isLoading, setLoading] = useState(true);
    const [parliamentaryMemberLists, setParliamentaryMemberLists] = useState([]);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    var parliamentaryId = activeUrl.split('/')[3];

    useEffect(() => {
        parliamentaryMemberList(1, 100).then((response) => {
            setParliamentaryMemberLists(response.data);
            setLoading(false);
        })
    }, []);

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            {
                                isLoading == true ?
                                    <LoadSpinner /> :
                                    parliamentaryMemberLists.length != 0 ?
                                        parliamentaryMemberLists.map((parliamentary) => {
                                            return parliamentary.ParliamentaryMemberWebApiViewModel.map((partyItem) => {
                                                if(parseInt(partyItem.Id) == parseInt(parliamentaryId)){
                                                    return (
                                                        <div key={partyItem.Id}>
                                                            <p className='opacity-50'>{partyItem.PartyName}</p>
                                                            <h2 className="my-3">{partyItem.MemberNameSurname}</h2>
                                                            <span className="c-badge text-dark text-start mb-4 ms-1">Meslek : {partyItem.MemberJobName}</span>
                                                            <span className="c-badge text-dark text-start mb-4 ms-1">Telefon : {partyItem.MemberPhoneNumber}</span>
                                                            <img src={partyItem.MediaUrl != null ? partyItem.MediaUrl : '/assets/media/background/default-image.jpeg'} className="detail-img rounded-15 mb-4" alt="..." />
                                                            { 
                                                            partyItem.MemberBiography != null ? 
                                                            ReactHtmlParser (partyItem.MemberBiography) : ''}
                                                        </div>
                                                    )
                                                }
                                            })
                                        })
                                        : <InfoCard />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}