import { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { socialMedia } from '../../services/services';
import { contactForm } from '../../services/services';
import { nameControl, phoneControl, detailTextControl, nameControlPaste } from '../../utils/utils';
import { LangContext, langs } from '../../locales/langContext';

export default function Contact() {
    const { language, setLanguage } = useContext(LangContext);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const [socialMedias, setSocialMedias] = useState([]);
    const [contactF, setContactF] = useState([]);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const [textarea, setTextarea] = useState();

    useEffect(() => {
        socialMedia().then((response) => {
            setSocialMedias(response.data);
        })
    }, []);

    const toastOptions = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined


    }

    const handleChange = (event) => {
        setTextarea(event.target.value)
      }

    const onSubmit = data => {
        const dataJson = JSON.stringify(data);
        console.log(textarea)
        contactForm(dataJson).then((response) => {
            setContactF(response.data);
            if (response.data.Success) {
                toast.success(response.data.Message, toastOptions);
                reset();
            }
            else {
                toast.error(response.data.Message, toastOptions)
            }
        })
    }

    

      

    return (
        <div>
            <div className="container py-5">
                <h1 className="text-light fw-bold pt-5">{language.contact}</h1>
                <p className="text-light opacity-50 pb-2">{language.home} / {language.contactL}</p>
            </div>
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-6">
                            <div className="card-side p-5">
                                <div className='mb-5'>
                                    {
                                        socialMedias.length != 0 ?
                                            <div>
                                                <div className='mb-3'>
                                                    <img src='/assets/media/icons/phoneDark.svg' width='16' />
                                                    <span className='ps-3'>{socialMedias.ContactInformation.Phone}</span>
                                                </div>
                                                <div className='mb-3'>
                                                    <img src='/assets/media/icons/faksDark.svg' width='16' />
                                                    <span className='ps-3'>{socialMedias.ContactInformation.Faks}</span>
                                                </div>
                                                <div className='mb-3'>
                                                    <img src='/assets/media/icons/emailDark.svg' width='16' />
                                                    <span className='ps-3'>{socialMedias.ContactInformation.Email}</span>
                                                </div>
                                                <div>
                                                    <img src='/assets/media/icons/pinDark.svg' width='16' />
                                                    <span className='ps-3'>{socialMedias.ContactInformation.Address}</span>
                                                </div>
                                            </div> : ''
                                    }
                                </div>
                                <ToastContainer />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="mb-3">
                                        <input type="text" className="form-control full-name-c p-3" placeholder={language.fullName} pattern=".*\S+.*" onKeyPress={nameControl}  {...register("Title", { required: true })} maxLength={250} />
                                    </div>
                                    <div className="mb-3">
                                        <input type="email" className="form-control text-c p-3" placeholder={language.email} pattern=".*\S+.*" onKeyPress={detailTextControl} {...register("Email", { required: true })} maxLength={256} />
                                        <div className="form-text e-mail-control-text text-danger d-none"></div>
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" className="form-control phone-c p-3" placeholder={language.phoneNumber} pattern=".*\S+.*" onKeyPress={phoneControl} {...register("PhoneNumber", { required: true })} maxLength={15} />
                                    </div>
                                    <div className="mb-3">
                                        <textarea className='form-control p-3 w-100' placeholder={language.messageText} rows='5' pattern=".*\S+.*" value={textarea} onChange={handleChange} id='TextMessage' onKeyUp={detailTextControl} onKeyPress={detailTextControl}s {...register("Detail", { required: true })}></textarea>
                                    </div>
                                    <div className="form-text mb-3">{language.requiredText}</div>
                                    <div className="form-text mb-3 fw-bold">{language.requiredInfo}</div>
                                    <button type="submit" className="btn btn-green p-3">{language.send}</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-6 m-t-space">
                            <div className="card-side h-100 p-5">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.3518818121715!2d41.54179841574848!3d40.82023397932035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406896e3566078d7%3A0x8417c4fb3ff1987!2sYusufeli%20Belediyesi!5e0!3m2!1str!2str!4v1643533953844!5m2!1str!2str" width="100%" height="100%" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}