import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import { presidentDetails } from '../../services/services';
import President from '../../components/president';
import InfoCard from '../../components/infoCard';
import InstitutionalMenu from '../../components/institutionalMenu';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';


export default function PresidentDetail() {
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;

    const [presidentDetail, setPresidentDetail] = useState([]);
    useEffect(() => {
        presidentDetails().then((response) => {
            setPresidentDetail(response.data);
            setLoading(false);
        })
    }, []);



    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            {
                                isLoading == true ?
                                    <LoadSpinner /> :
                                    presidentDetail.length != 0 ?
                                        <div>
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <h2 className="my-3">{presidentDetail.Name + ' ' + presidentDetail.LastName}</h2>
                                                    <p className='opacity-50'>{presidentDetail.Title}</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <ul className="nav me-auto justify-content-end">
                                                        {
                                                            presidentDetail.MayorSocialMediaAccountsViewModel.length != 0 ?
                                                                presidentDetail.MayorSocialMediaAccountsViewModel.map((socialMedia) => {
                                                                    return (
                                                                        <li key={socialMedia.IconUrl} className="nav-item">
                                                                            <a target='_blank' href={socialMedia.SocialUrl} className="btn">
                                                                                <img src={`/assets/media/icons/${socialMedia.IconUrl}`} />
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                }) : ''
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <img src={
                                                presidentDetail.Media[0].MediaUrl != null ?
                                                    presidentDetail.Media[0].MediaUrl.replace('/original/', '/780/')
                                                    : '/assets/media/background/default-image.jpeg'
                                            } className="detail-img rounded-15 mb-4" alt="..." />
                                            {presidentDetail.Detail!= null ? presidentDetail.Detail.split("\n").map((line, idx) => <p key={idx}>{line}</p>) : ''}
                                        </div>
                                        : <InfoCard />
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}