import {createContext, useState} from "react";


export const langs = {
    tr : {
        langu :"tr",
        languNumber : 1,
        home:"Ana Sayfa",
        error:"Hata",
        errorTitle:"HATA",
        errorSubTitle: "Bir Hata Oluştu !",
        errorDesc: "Aradığınız Sayfayı Bulamadık !",
        errorDescReload:"Lütfen Tekrar Deneyiniz...",
        fastAccess: "Hızlı Erişim",
        detail : "Detay",
        menu:"MENÜ",
        copyright:"© 2022 Yusufeli Belediyesi - Her Hakkı Saklıdır",
        footerText : "Yusufeli bölgesi, Çoruh Nehri havzasında yer alan batıda Bayburt, İspir, doğuda Oltu ve kuzeyde Artvin bölgeleri kültür çevresinde yer almaktadır. Nehir havzaları insanoğlunun ilk önce yerleşik hayata geçerek tarım toplumunu oluşturduğu yerlerdir.",
        presidentTitle:"BAŞKAN",
        fullName:"Ad - Soyad",
        email:"E-Posta",
        phoneNumber : "Telefon",
        messageText : "Mesaj",
        citySelect:"Şehir Seçiniz",
        tckn:"TCKN",
        send:"Gönder",
        save:"Kaydet",
        more:"Daha Fazla Yükle",
        allText:"Tümü",
        notDataInfo :"Aradığınız Veri Bulunamadı",
        messageInbox:"Gelen Mesajlar",
        requiredText : "Tüm alanlar zorunludur.",
        requiredInfo : "Bilgi : Mesajınız ile ilgili geri dönüşler e-posta adresiniz üzerinden yapılacaktır.",
        news : "HABERLER",
        newsAll: "Tüm Haberler",
        activity:"ETKİNLİKLER",
        activityAll:"Tüm Etkinlikler",
        announcement:"DUYURULAR",
        announcementAll:"Tüm Duyurular",
        project:"PROJELER",
        projectAll:"Tüm Projeler",
        ebooks : "E-KİTAPLAR",
        mobileAppTitle :"YUSUFELİ MOBİL UYGULAMA",
        mobileAppSubTitle : "Mobil Uygulamayı Hemen İndirin.Tek Bir Uygulama ve Hepsi Bir Arada.",
        contact: "İLETİŞİM",
        contactL:"İletişim",
        visitorBook:"ZİYARETÇİ DEFTERİ",
        visitorBookL:"Ziyaretçi Defteri",
        textSpace : "Alan Boş Geçilemez !"

    },
    en:{
        langu :"en",
        languNumber : 2,
        home:"Home",
        error:"Error",
        errorTitle:"ERROR",
        errorSubTitle: "Something Went Wrong !",
        errorDesc: "No Page Found !",
        errorDescReload:"Please Try Again...",
        fastAccess : "Fast Access",
        detail : "Detail",
        menu:"MENU",
        copyright:"© 2022 Yusufeli Belediyesi - All Rights Reserved",
        footerText : "Yusufeli region is located in the cultural environment of Bayburt, İspir in the west, Oltu in the east and Artvin in the north, which are located in the Çoruh River basin. River basins are places where human beings first settled down and formed an agricultural society.",
        presidentTitle:"PRESIDENT",
        fullName:"Name - Surname",
        email:"E-Mail",
        phoneNumber : "Phone",
        messageText : "Message",
        citySelect:"City Choose",
        tckn:"TCKN",
        send:"Send",
        save:"Save",
        more:"More",
        allText:"All",
        notDataInfo :"No Data Found !",
        messageInbox:"Inbox Message",
        requiredText : "All fields are required.",
        requiredInfo : "Information: Feedback about your message will be made via your e-mail address.",
        news : "NEWS",
        newsAll: "All News",
        activity:"ACTIVITYS",
        activityAll:"All Activitys",
        announcement:"ANNOUNCEMENTS",
        announcementAll:"All Announcements",
        project:"PROJECTS",
        projectAll:"All Projects",
        ebooks : "E-BOOKS",
        mobileAppTitle :"YUSUFELI MOBILE APP",
        mobileAppSubTitle : "Download Mobile App Now. One App and All in One.",
        contact: "CONTACT",
        contactL:"Contact",
        visitorBook:"VISITOR BOOK",
        visitorBookL:"Visitor Book",
        textSpace : "Field Cannot Be Empty !"
    }
}

export const LangContext = createContext()
