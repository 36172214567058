import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import InstitutionalMenu from "../../components/institutionalMenu";
import BreadCrump from "../../components/breadCrump";
import InfoCard from "../../components/infoCard";
import President from "../../components/president";
import { helpContacts } from "../../services/services";
import LoadSpinner from "../../components/loadSpinner";

export default function HelpContactNumber() {
  const [isLoading, setLoading] = useState(true);
  const [helpCont, setHelpCont] = useState([]);
  const urlLocation = useLocation();
  const activeUrl = urlLocation.pathname;

  useEffect(() => {
    helpContacts("1", "100").then((response) => {
      setHelpCont(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <BreadCrump />
      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5 col-xl-3">
            <InstitutionalMenu />
              <President />
            </div>
            <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
              {
                isLoading == true ?
                  <LoadSpinner /> :
                  helpCont.length != 0 ?
                  helpCont.map((helpCon, index) => {
                      return (
                        <a href={"tel:" + helpCon.PhoneNumber} className="text-decoration-none text-black">
                          <div key={helpCon.Id} className="card-side p-4 mb-3">
                            <img width="32" src="/assets/media/icons/helpContact.svg" />
                            <span className="fw-bolder ms-3">{helpCon.Header.length > 100 ? helpCon.Header.substring(0,100) + '...' : helpCon.Header}</span>
                            <p className="opacity-75 ms-5">{helpCon.PhoneNumber}</p>
                            {
                              helpCon.Email != null ? <p className="opacity-75 ms-5">{helpCon.Email}</p> : ''
                            }
                          </div>
                        </a>
                      );
                    }) :
                    <InfoCard />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
