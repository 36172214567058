import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import Carousel from 'react-elastic-carousel';
import FsLightbox from 'fslightbox-react';
import BreadCrump from '../../components/breadCrump';
import InstitutionalMenu from '../../components/institutionalMenu';
import President from '../../components/president';
import { dinamicDescriptionPages } from "../../services/services";
import InfoCard from '../../components/infoCard';
import LoadSpinner from '../../components/loadSpinner';

export default function DescriptionDetail() {
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const urlPageName = activeUrl.split("/")[2];
    const [dinamicDescriptionPage, setDinamicDescriptionPage] = useState([]);
    useEffect(() => {
        dinamicDescriptionPages(activeUrl).then((response) => {
            setDinamicDescriptionPage(response.data);
            setLoading(false);
        })
    }, []);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    //Carousel 
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 }
    ];

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

   // console.log(dinamicDescriptionPage)

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        {
                            isLoading == true ?
                                <LoadSpinner /> :
                                dinamicDescriptionPage.length != 0 ?
                                    <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                                        <h2 className="my-3">{dinamicDescriptionPage.Text}</h2>
                                        <img src={dinamicDescriptionPage.MediaUrl != "" ? dinamicDescriptionPage.MediaUrl :'/assets/media/background/default-image.jpeg'} className="detail-img rounded-15 mb-4" alt="..." />
                                        {ReactHtmlParser(dinamicDescriptionPage.Detail)}
                                        {
                                                dinamicDescriptionPage.MultiMediaUrl.length != 0 ?
                                                    <div>
                                                        <Carousel itemsToShow={3} breakPoints={breakPoints} className='multi-carosuel mt-5'>
                                                            {
                                                                dinamicDescriptionPage.MultiMediaUrl.map((mediasUrls, index) => {
                                                                    return (
                                                                        <div onClick={() => openLightboxOnSlide(index + 1)} key={index} className="card bg-transparent border-0 px-2">
                                                                            <img className="img-fluid card-img-top-media" src={mediasUrls} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Carousel>
                                                        <FsLightbox
                                                            toggler={lightboxController.toggler}
                                                            sources={dinamicDescriptionPage.MultiMediaUrl}
                                                            slide={lightboxController.slide}
                                                        />
                                                    </div> : ''
                                            }
                                    </div> : <InfoCard />
                        }

                    </div>
                </div>
            </div>

        </div>
    )
}