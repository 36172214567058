import { useState,useEffect } from 'react';
import { Link,useLocation } from 'react-router-dom';
import {menuAll} from '../services/services'

export default function SideMenu(){
    const [allMenu,setAllMenu] = useState([]); 
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    var activeUrlSplit = activeUrl.split('/');
    var localUrl = '/' + activeUrlSplit[1];

    
    useEffect(() => {
    menuAll().then((response)=>{
        setAllMenu(response.data[2].WebPartialMenuViewModel);
    })
    },[localUrl[1]]);

    return (
        <div role="banner">
                <div className="side-menu-nav mt-3" role="navigation">
                <ul className="side-menu-nav__list p-0 m-0">
                {allMenu.map((webMenu)=>{
                     var cActive = "";
                     var subShow = "";

                     if(activeUrl == webMenu.WebMenu.PrefixRoute){
                        cActive = "sm-active disabled";
                     }
                     else{
                        if(localUrl == webMenu.WebMenu.PrefixRoute){
                            cActive = "sm-active disabled";
                            subShow ="side-m-show";
                        }
                        else{
                            cActive = "";
                        }
                     }

                    if(webMenu.child == ""){
                        return (
                            <li className='no-subs' key={webMenu.Id}><Link to={webMenu.WebMenu.PrefixRoute} className={cActive}>{webMenu.WebMenu.Text}</Link></li>
                        )
                    }
                    else{
                        return( 
                            <li key={webMenu.Id}>
                                <input id={webMenu.Id} type="checkbox" hidden />
                                <label htmlFor={webMenu.Id} className={cActive}><span className="fa fa-angle-right"></span>{webMenu.WebMenu.Text}</label>
                                <ul className="group-list p-0">
                                {
                                webMenu.child.map((subMenu)=>{
                                    if (subMenu.WebMenu.TargetBlank == true) {
                                        return (
                                            <li key={subMenu.Id}><a  target='_blank' href={subMenu.WebMenu.Route}>{subMenu.WebMenu.Text}</a></li>
                                        );
                                    }
                                    else {
                                        return (
                                            <li key={subMenu.Id}><Link to={subMenu.WebMenu.PrefixRoute + subMenu.WebMenu.Route}>{subMenu.WebMenu.Text}</Link></li>
                                        );
                                    }
                                })
                            }
                                </ul>
                                </li>
                            );
                    }
                    
                })}
                </ul>
                </div>
            </div>
    );
}