import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AccessMenu from "../../components/accessMenu";
import BreadCrump from "../../components/breadCrump";
import InfoCard from "../../components/infoCard";
import President from "../../components/president";
import { announcementDetail } from "../../services/services";
import LoadSpinner from "../../components/loadSpinner";
import ReactHtmlParser from "html-react-parser";
import FsLightbox from "fslightbox-react";

export default function AnnouncementsDetail() {
  const [isLoading, setLoading] = useState(true);
  const urlLocation = useLocation();
  const activeUrl = urlLocation.pathname;
  const urlId = activeUrl.split("/")[3];
  const [toggler, setToggler] = useState(false);

  const [announcementsDetail, setAnnouncementsDetail] = useState([]);
  useEffect(() => {
    announcementDetail(urlId).then((response) => {
      setAnnouncementsDetail(response.data);
      setLoading(false);
    });
  }, []);


  return (
    <div>
      <BreadCrump />
      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5 col-xl-3">
              <AccessMenu />
              <President />
            </div>
            <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
              {isLoading == true ? (
                <LoadSpinner />
              ) : announcementsDetail.length != 0 ? (
                <div>
                  <small className="card-text opacity-75">
                    {announcementsDetail.NearDateLocal}
                  </small>
                  <h2 className="my-3">{announcementsDetail.Title}</h2>
                  <img onClick={() => setToggler(!toggler)}
                    src={
                      announcementsDetail.MediaURL != null
                        ? announcementsDetail.MediaURL
                        : "/assets/media/background/default-image.jpeg"
                    }
                    className="detail-img rounded-15 mb-4"
                    alt="..."
                    style={{cursor:"pointer" }}
                  />
                  <p className="fw-bold">{announcementsDetail.Summary}</p>
                  <p>
                    {announcementsDetail.Detail != null
                      ? announcementsDetail.Detail.split("\n").map(
                          (line, idx) => <p key={idx}>{line}</p>
                        )
                      : ""}
                  </p>
                </div>
              ) : (
                <InfoCard />
              )}
            </div>
          </div>
        </div>
      </div>
      <FsLightbox
        toggler={toggler}
        sources={[announcementsDetail.MediaURL != null ? announcementsDetail.MediaURL : "/assets/media/background/default-image.jpeg" ]}
      />
    </div>
  );
}
