import { useState, useEffect,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BreadCrump from '../../components/breadCrump';
import InfoCard from '../../components/infoCard';
import InstitutionalMenu from '../../components/institutionalMenu';
import LoadSpinner from '../../components/loadSpinner';
import President from '../../components/president';
import { travelGuideList } from '../../services/services';
import { LangContext,langs } from '../../locales/langContext';


export default function Travel() {
    const {language, setLanguage} = useContext(LangContext);
    const [travelAllList, setTravelAllList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const [pageCount, setPageCount] = useState(15);
    const [btnDisabled, setBtnDisabled] = useState(false);

    useEffect(() => {
        travelGuideList('1', pageCount).then((response) => {
            setTravelAllList(response.data);
            setLoading(false);
            if(response.data.length != 0){
                if(response.data[0].TotalItem <= pageCount){
                    setBtnDisabled(true);
                }
                else{
                    setBtnDisabled(false);
                }
            }
            else{
                setBtnDisabled(true);
            }
        })
    }, []);

    const loadData = () => {
        var pageItem = pageCount + 9
        setPageCount(pageItem)
        travelGuideList('1', pageItem).then((response) => {
            setTravelAllList(response.data);
            setLoading(false);
            if(travelAllList[0].TotalItem == response.data.length) {
                setBtnDisabled(true);
            }
            else {
                setBtnDisabled(false);
            }
        });
    }


    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <div className="row">
                                {
                                    isLoading == true ?
                                        <LoadSpinner /> :
                                        travelAllList.length != 0 ?
                                            travelAllList.map((travelList) => {
                                                return (
                                                    <div key={travelList.Id} className="col-md-12 col-lg-6 col-xl-4 mb-3">
                                                        <Link to={activeUrl + '/' + travelList.Id} className="text-dark text-decoration-none">
                                                            <div className="card custom-cards border-0 px-2 mb-3 bg-transparent">
                                                                <img src={travelList.MediaURL} className="card-img-top rounded-15"
                                                                    alt="..." />
                                                                <div className="card-body bg-opacity-100">
                                                                    <h6 className="card-title custom-card-title">{travelList.Header.length > 60 ? travelList.Header.substring(0, 60) +'...': travelList.Header}</h6>
                                                                    <div className="d-flex justify-content-between mt-">
                                                                        {travelList.Category.map((categories, index) => {
                                                                            if (index < 1) {
                                                                                return (<span key={travelList.Id} className="c-badge text-dark text-start">{categories.length > 40 ? categories.substring(0,40) + '...' : categories}</span>)
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                );
                                            }) :
                                            <InfoCard />
                                }
                            </div>
                            <div className='text-center'><button onClick={loadData} disabled={btnDisabled} className='btn btn-green p-3 px-4'>{language.more}</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}