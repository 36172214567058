import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import Carousel from 'react-elastic-carousel';
import President from '../../components/president';
import { socialServiceDetail } from "../../services/services";
import InfoCard from '../../components/infoCard';
import LoadSpinner from '../../components/loadSpinner';
import FsLightbox from 'fslightbox-react';
import InstitutionalMenu from '../../components/institutionalMenu';
import BreadCrump from '../../components/breadCrump';

export default function SocialServicesDetail() {
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const urlId = activeUrl.split("/")[3];
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    //Carousel 
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 }
    ];

    const [socialServiceDetails, setSocialServiceDetails] = useState([]);
    useEffect(() => {
        socialServiceDetail(urlId).then((response) => {
            setSocialServiceDetails(response.data);
            setLoading(false);
        })
    }, []);

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    return (
        <div>
            <BreadCrump/>
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu/>
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            {
                                isLoading == true ?
                                    <LoadSpinner /> :
                                    socialServiceDetails.length != 0 ?
                                        <div>
                                            {socialServiceDetails.Category.map((detailCategories) => {
                                                return (<span key={detailCategories.CategoriesID} className="c-badge text-dark text-start me-3">{detailCategories}</span>)
                                            })}
                                            <h2 className="my-3">{socialServiceDetails.Header}</h2>
                                            <img src={socialServiceDetails.MediaUrl != null ? socialServiceDetails.MediaUrl.replace('/original/', '/780/') : '/assets/media/background/default-image.jpeg'} className="detail-img rounded-15 mb-4" alt="..." />
                                            <p>{socialServiceDetails.Detail != null ? socialServiceDetails.Detail.split("\n").map((line, idx) => <p key={idx}>{line}</p>) : ''}</p>
                                            {/* ReactHtmlParser (socialServiceDetails.Detail) */}
                                            <div className="card-side text-center mt-4 p-5">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                        <p className="opacity-50">Telefon - 1</p>
                                                        <p className="fw-bolder">{socialServiceDetails.ContactPhoneNumber1}</p>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                        <p className="opacity-50">Telefon - 2</p>
                                                        <p className="fw-bolder">{socialServiceDetails.ContactPhoneNumber2}</p>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                                                        <p className="opacity-50">Adres</p>
                                                        <p className="fw-bolder">{socialServiceDetails.Address}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                socialServiceDetails.OtherMedias != 0 ?
                                                    <div>
                                                        <Carousel itemsToShow={3} breakPoints={breakPoints} className='multi-carosuel mt-5'>
                                                            {
                                                                socialServiceDetails.OtherMedias.map((mediasUrls, index) => {
                                                                    return (
                                                                        <div onClick={() => openLightboxOnSlide(index + 1)} key={`media-${index}`} className="card bg-transparent border-0 px-2">
                                                                            <img className="img-fluid card-img-top-media" src={mediasUrls.replace('/original/', '/300/')} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Carousel>
                                                        <FsLightbox
                                                            toggler={lightboxController.toggler}
                                                            sources={socialServiceDetails.OtherMedias}
                                                            slide={lightboxController.slide}
                                                        />
                                                    </div> : ''
                                            }
                                        </div>
                                        : <InfoCard />
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}