import { useState,useEffect,useContext } from 'react';
import { LangContext,langs } from '../locales/langContext';

export default function InfoCard(){
    const {language, setLanguage} = useContext(LangContext);

    return (
        <div className="alert alert-secondary bg-transparent border-0 py-5 text-center" role="alert">
            <div><img className='opacity-50 mb-3' src='/assets/media/icons/infoAlert.svg' width="64"/></div>
            <small className='opacity-75'>{language.notDataInfo}</small>
        </div>
    );
}