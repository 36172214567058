import { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import InstitutionalMenu from '../../components/institutionalMenu';
import President from '../../components/president';
import { dinamicImagesPages } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';


export default function ImageListPage() {
    const [imageLists, setImageLists] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const urlPageName = activeUrl.split("/")[2];

    useEffect(() => {
        dinamicImagesPages(activeUrl).then((response) => {
            setImageLists(response.data);
            setLoading(false);
        });
    }, []);

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <div className='row'>
                                {
                                    isLoading == true ?
                                        <LoadSpinner /> :
                                        imageLists.length != 0 ?
                                            imageLists.WebPictureList.map((lists) => {
                                                return (
                                                    <div key={lists.Id} className="col-md-12 col-lg-6 col-xl-4">
                                                        <div className="card border-0 px-2 mb-3 bg-transparent">
                                                            <img src={lists.FileUrl} className="card-img-top rounded-15" alt="..." />
                                                            <div className="card-body bg-opacity-100 text-center">
                                                                <h6 className="card-title">{lists.Title}</h6>
                                                                <small className="card-text opacity-75">{lists.Detail}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <InfoCard />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}