import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import President from '../../components/president';
import InfoCard from '../../components/infoCard';
import { useForm } from 'react-hook-form';
import InstitutionalMenu from '../../components/institutionalMenu';
import BreadCrump from '../../components/breadCrump';
import { wishMessages } from '../../services/services';
import AccessMenu from '../../components/accessMenu';
import { nameControl,phoneControl, detailTextControl,tcknControl, numberControl } from '../../utils/utils';



export default function WishMessage() {
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const [wishMessa, setWishMessa] = useState([]);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

    const toastOptions = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    }

    const onSubmit = data => {
        const dataJson = JSON.stringify(data);
        wishMessages(dataJson).then((response) => {
            setWishMessa(response.data);
            if(response.data.Success){
                toast.success(response.data.Message, toastOptions);
                reset();
            }
            else{
                toast.error(response.data.Message, toastOptions)
            }

        })
    }

    /*const textControls =(e)=>{
        var regex = new RegExp("^[^<> ]+$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
          return true;
        }
         e.preventDefault();
        return false;
    }*/

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <AccessMenu/>
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <ToastContainer/>
                            <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                    <input type="text" className="form-control p-3" id='TcNumbers' placeholder='TCKN' onBlurCapture={tcknControl} onKeyPress={numberControl} pattern=".*\S+.*" {...register("CitizenId", { required: true ,pattern: /^[0-9]+$/i })} minLength={11} maxLength={11} />
                                    <div className="form-text tc-control-text text-danger d-none"></div>
                                </div>
                                <div className="mb-3">
                                        <input type="text" className="form-control p-3" placeholder='Ad - Soyad' onKeyPress={nameControl} pattern=".*\S+.*"  {...register("Title", { required: true })} maxLength={250} />
                                    </div>
                                    <div className="mb-3">
                                        <input type="email" className="form-control p-3" placeholder='E-Posta' onKeyPress={detailTextControl} pattern=".*\S+.*" {...register("Email", { required: true })} maxLength={256} />
                                        <div className="form-text e-mail-control-text text-danger d-none"></div>
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" className="form-control p-3" placeholder='Telefon' onKeyPress={phoneControl} pattern=".*\S+.*" {...register("PhoneNumber", { required: true })} maxLength={15} />
                                    </div>
                                    <div className="mb-3">
                                        <textarea className='form-control p-3 w-100' placeholder='Mesaj' rows='5' id='TextMessage' onKeyUp={detailTextControl} onKeyPress={detailTextControl} pattern=".*\S+.*" {...register("Detail", { required: true })}></textarea>
                                    </div>
                                <div className="form-text mb-3">Tüm alanlar zorunludur.</div>
                                <div className="form-text mb-3 fw-bold">Bilgi : Mesajınız ile ilgili geri dönüşler e-posta adresiniz üzerinden yapılacaktır.</div>
                                <button type="submit" className="btn btn-green p-3">Gönder</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}