import { useState,useEffect,useContext } from 'react';
import { Link,useLocation } from 'react-router-dom';
import { menuAll } from '../services/services';
import { LangContext,langs } from '../locales/langContext';


export default function BreadCrump(){
    const {language, setLanguage} = useContext(LangContext);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    var activeUrlSplit = activeUrl.split('/');
    var moduleUrlName = '/' + activeUrlSplit[1];
    var childUrlName = '/' + activeUrlSplit[2];
    var breadTitle = '';
    var childTitle = '';
    var breads = '';

    const [breadCrumps,setBreadCrumps] = useState([]); 
    useEffect(() => {
      menuAll().then((response)=>{
        if(response.data != ''){
            response.data.map((menuType,index)=>{
                if(menuType.Name == 'TopMenu'){
                    menuType.WebPartialMenuViewModel.map((topMenu,index)=>{
                        if(topMenu.WebMenu.PrefixRoute == moduleUrlName){
                            setBreadCrumps(response.data[2].WebPartialMenuViewModel[index]);
                        }
                    })
                }
                else if(menuType.Name == 'SubMenu'){
                    menuType.WebPartialMenuViewModel.map((topMenu,index)=>{
                        if(topMenu.WebMenu.PrefixRoute == moduleUrlName){
                            setBreadCrumps(response.data[4].WebPartialMenuViewModel[index]);
                        }
                    })
                }
                else if(menuType.Name == 'MenuInside'){
                    menuType.WebPartialMenuViewModel.map((inside)=>{
                        
                        if(inside.MenuId == 8){
                            // Inside Menu
                            inside.child.map((childs)=>{
                               
                                var apiRoutes = childs.WebMenu.PrefixRoute + childs.WebMenu.Route
                                if(apiRoutes == activeUrl){
                                    setBreadCrumps(response.data[1].WebPartialMenuViewModel[index]);
                                }
                                else{
                                    setBreadCrumps(response.data[1].WebPartialMenuViewModel[index]);
                                }
                            })
                        }
                        
                    })
                     
                }
                else {}
            })
        }
    })},[]);

    if(breadCrumps != ''){
        
        if(breadCrumps.MenuId != 8){
            // Top Menu BreadCrump
            //-- Child
            breadCrumps.child.map((childItem)=>{
                if(childItem.WebMenu.Route == childUrlName){
                    childUrlName = childItem.WebMenu.Text;
                    breadTitle = breadCrumps.WebMenu.Text;
                }
            });
            breads = language.home + ' / ' + breadTitle + ' / ' + childUrlName;
        }
        else {
            // Inside Menu BreadCrump
            //-- Child
            var insideBreadTitle = '';
            breadCrumps.child.map((childItem)=>{
                var apiRoutes = childItem.WebMenu.PrefixRoute + childItem.WebMenu.Route
                
                if(apiRoutes == activeUrl){
                    breadTitle = childItem.WebMenu.Text;
                    insideBreadTitle = childItem.WebMenu.Text;
                    breads = language.home + ' / ' + language.fastAccess+ ' / ' + breadTitle;  
                }

                if(activeUrlSplit[3] > 0 && '/'+ activeUrlSplit[2] == childItem.WebMenu.Route){
                    breadTitle = language.detail;
                    insideBreadTitle = childItem.WebMenu.Text;
                    breads = language.home + ' / ' + language.fastAccess + ' / ' + insideBreadTitle + ' / ' + breadTitle;
                }                
            });
            
        }
    }

    return ( 
        <div className="container py-5">
            <h1 className="text-light text-uppercase fw-bold pt-5">{breadTitle}</h1>
            <p className="text-light opacity-50 pb-2">{breads}</p>
        </div>
    );
}