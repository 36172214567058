import { useState, useEffect,useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import AccessMenu from "../../components/accessMenu";
import BreadCrump from "../../components/breadCrump";
import InfoCard from "../../components/infoCard";
import President from "../../components/president";
import { announcementList } from "../../services/services";
import LoadSpinner from "../../components/loadSpinner";
import { LangContext,langs } from "../../locales/langContext";

export default function Announcement() {
  const {language, setLanguage} = useContext(LangContext);
  const [isLoading, setLoading] = useState(true);
  const [announcementsList, setAnnouncementList] = useState([]);
  const urlLocation = useLocation();
  const activeUrl = urlLocation.pathname;
  const [pageCount, setPageCount] = useState(20);
  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    announcementList("1", pageCount).then((response) => {
      setAnnouncementList(response.data);
      setLoading(false);
      if(response.data.length != 0){
        if(response.data[0].TotalItem <= pageCount){
            setBtnDisabled(true);
        }
        else{
            setBtnDisabled(false);
        }
    }
    else{
        setBtnDisabled(true);
    }
    });
  }, []);

  const loadData = () => {
    var pageItem = pageCount + 9
    setPageCount(pageItem)
    announcementList('1', pageItem).then((response) => {
      setAnnouncementList(response.data);
      setLoading(false);
      if(announcementsList[0].TotalItem <= response.data.length) {
        setBtnDisabled(true);
      }
      else {
        setBtnDisabled(false);
      }
      
    });
  }

  return (
    <div>
      <BreadCrump />
      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5 col-xl-3">
              <AccessMenu />
              <President />
            </div>
            <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
              {
                isLoading == true ?
                  <LoadSpinner /> :
                  announcementsList.length != 0 ?
                    announcementsList.map((announcements, index) => {
                      return (
                        <Link key={announcements.Id} to={activeUrl + '/' + announcements.Id} className="text-dark text-decoration-none">
                          <div key={announcements.Id} className="card-side c-hover p-4 mb-3">
                            <img width="32" src="/assets/media/icons/announcement.svg" />
                            <span className="fw-bolder ms-3">{announcements.Header.length > 100 ? announcements.Header.substring(0,100) + '...' : announcements.Header}</span>
                          </div>
                        </Link>
                      );
                    }) :
                    <InfoCard />
              }
              <div className='text-center mt-5'><button onClick={loadData} disabled={btnDisabled} className='btn btn-green p-3 px-4'>{language.more}</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
