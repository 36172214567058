import { useState, useEffect,useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { guestBookForm, guestBooks } from '../../services/services';
import LoadSpinner from '../../components/loadSpinner';
import InfoCard from '../../components/infoCard';
import { nameControl, detailTextControl } from '../../utils/utils';
import { LangContext,langs } from '../../locales/langContext';

export default function VisitorBook() {
    const {language, setLanguage} = useContext(LangContext);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const [guestF, setGuestF] = useState([]);
    const [guesttBook, setGuesttBook] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

    useEffect(() => {
        guestBooks().then((response) => {
            setGuesttBook(response.data);
            setLoading(false);
        });
    }, []);

    const toastOptions = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined


    }

    const onSubmit = data => {
        const dataJson = JSON.stringify(data);
        guestBookForm(dataJson).then((response) => {
            setGuestF(response.data);
            console.log(dataJson)
            if (response.data.Success) {
                toast.success(response.data.Message, toastOptions);
                reset();
            }
            else {
                toast.error(response.data.Message, toastOptions)
            }

        })
    }

    return (
        <div>
            <div className="container py-5">
                <h1 className="text-light fw-bold pt-5">{language.visitorBook}</h1>
                <p className="text-light opacity-50 pb-2">{language.home} / {language.fastAccess} / {language.visitorBookL}</p>
            </div>
            <div className="container-fluid" style={{background:"#FFF"}}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 col-xl-5">
                            <div className="card-side p-5">
                                <ToastContainer />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="mb-3">
                                        <input type="text" className="form-control p-3" placeholder={language.fullName} onKeyPress={nameControl} pattern=".*\S+.*" {...register("Title", { required: true })} maxLength={250} />
                                    </div>
                                    <div className="mb-3">
                                        <select className="form-select form-control p-3" {...register("refCityId",{ required: true })}>
                                        <option value="">{language.citySelect}</option>
                                            {
                                                guesttBook.Cities != undefined ?
                                                    guesttBook.Cities.length != 0 ?
                                                        guesttBook.Cities.map((city) => {
                                                            return (
                                                                <option key={city.Id} value={city.Id}>{city.CityName}</option>
                                                            )
                                                        }) : <option value="">-</option>

                                                    : ''
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <textarea className='form-control p-3 w-100' placeholder={language.messageText} rows='5' pattern=".*\S+.*" id='TextMessage' onKeyUp={detailTextControl} onKeyPress={detailTextControl} {...register("Detail", { required: true })}></textarea>
                                    </div>
                                    <div className="form-text mb-3">{language.requiredText}</div>
                                    <button type="submit" className="btn btn-green p-3">{language.save}</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-8 col-xl-7 m-t-space">
                            <div className="card-side h-100 p-5">
                                <h4>{language.messageInbox}</h4>
                                {
                                    isLoading == true ?
                                        <LoadSpinner /> :
                                        guesttBook.WebGuestbookAPIViewModels.length != 0 ?
                                            <div>
                                                {
                                                    guesttBook.WebGuestbookAPIViewModels.map((data) => {
                                                        return (

                                                            <div className="list-group card p-4 mb-3" key={data.Id}>
                                                                 <small className="text-muted mb-2">{data.InsertDateString}</small>
                                                                <div className="d-flex w-100 justify-content-between">
                                                                    <h6 className="mb-1 fw-bold"><span className="badge bg-secondary me-2">{data.CityName}</span>{data.Title}</h6>
                                                                   
                                                                </div>
                                                                <p className="mb-1 mt-2">{data.Detail}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> : <InfoCard />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}