import { useState, useEffect,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import Carousel from 'react-elastic-carousel';
import AccessMenu from '../../components/accessMenu';
import President from '../../components/president';
import { newDetail } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';
import FsLightbox from 'fslightbox-react';
import { LangContext,langs } from '../../locales/langContext';



export default function NewsDetail() {
    const {language, setLanguage} = useContext(LangContext);
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const urlId = activeUrl.split("/")[3];
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    //Carousel 
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 }
    ];

    const [newsDetails, setNewsDetails] = useState([]);
    useEffect(() => {
        newDetail(urlId,language.languNumber).then((response) => {
            setNewsDetails(response.data);
            setLoading(false);
        })
    }, [language]);

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <AccessMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">

                            {
                                isLoading == true ?
                                    <LoadSpinner /> :
                                    newsDetails.length != 0 ?
                                        <div>
                                            {
                                                newsDetails.Categories.map((detailCategories) => {
                                                    return (<span key={detailCategories.CategoriesID} className="c-badge text-dark text-start me-1">{detailCategories}</span>)
                                                })}
                                            <small className="card-text opacity-75 ms-3">{newsDetails.NearDateLocal}</small>
                                            <h2 className="my-3">{newsDetails.Title}</h2>
                                            <img src={newsDetails.MediaURL != null ? newsDetails.MediaURL.replace('/original/', '/780/') : '/assets/media/background/default-image.jpeg'} className="detail-img rounded-15 mb-4" alt="..." />
                                            <p className='fw-bold'>{newsDetails.Summary}</p>
                                            {newsDetails.Detail!= null ? newsDetails.Detail.split("\n").map((line, idx) => <p key={idx}>{line}</p>) : ''}
                                            {
                                                newsDetails.OtherMedias != 0 ?
                                                    <div>
                                                        <Carousel itemsToShow={3} breakPoints={breakPoints} className='multi-carosuel mt-5'>
                                                            {
                                                                newsDetails.OtherMedias.map((mediasUrls, index) => {
                                                                    return (
                                                                        <div onClick={() => openLightboxOnSlide(index + 1)} key={index} className="card bg-transparent border-0 px-2">
                                                                            <img className="img-fluid card-img-top-media" src={mediasUrls.replace('/original/', '/300/')} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Carousel>
                                                        <FsLightbox
                                                            toggler={lightboxController.toggler}
                                                            sources={newsDetails.OtherMedias}
                                                            slide={lightboxController.slide}
                                                        />
                                                    </div> : ''
                                            }
                                        </div> : <InfoCard />
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}