import react from 'react';
import { useState, useEffect,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { menuAll, socialMedia } from '../services/services';
import { LangContext,langs } from '../locales/langContext';

export default function Footer() {
    const {language, setLanguage} = useContext(LangContext)
    const [footerMenu, setFooterMenu] = useState([]);
    const [privacyMenu, setPrivacyMenu] = useState([]);
    const [socialMedias, setSocialMedias] = useState([]);

    useEffect(() => {
        menuAll(language.languNumber).then((response) => {
            setFooterMenu(response.data[0].WebPartialMenuViewModel);
            setPrivacyMenu(response.data[4].WebPartialMenuViewModel[0].child);
        })
    }, [language]);

    useEffect(() => {
        socialMedia().then((response) => {
            setSocialMedias(response.data);
        })
    }, []);


    return (
        <footer className="container-fluid bg-green p-0">
            <div className="container p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-3 pe-4">
                        <div className="d-flex bd-highlight opacity-75 align-items-center">
                            <div className="p-2 flex-grow-1 bd-highlight">
                                <img src="/assets/media/logo/yusufeli_logo.svg" />
                            </div>
                            <div className="p-2 bd-highlight">
                                <ul className="nav me-auto">
                                    {
                                        socialMedias.length != 0 ?
                                            socialMedias.AppSiteSocialMediaAccounts.map((social) => {
                                                if(social.IconUrl.split('/')[5] == 'material-live-tv.png'){
                                                    return (
                                                        <li key={social.Id} className="nav-item">
                                                            <a href={social.SocialUrl} target="_blank" className="nav-link link-light" aria-current="page">
                                                                <img width='18' src={social.IconUrl}/>
                                                            </a>
                                                        </li>
                                                    )
                                                    
                                                }   
                                                else{
                                                    return (
                                                        <li key={social.Id} className="nav-item">
                                                            <a href={social.SocialUrl} target="_blank" className="nav-link link-light" aria-current="page">
                                                                <i className={social.IconUrl}></i>
                                                            </a>
                                                        </li>
                                                    )
                                                }   
                                            }) : ''
                                    }
                                </ul>
                            </div>
                        </div>
                       <p>{language.name}</p>
                        <p className="blockquote-footer text-light mt-4">{language.footerText}</p>
                    </div>
                    {footerMenu.map((fMenu) => {
                        if (fMenu.WebMenu.Controller != 'Iletisim') {
                            return (
                                <div key={fMenu.Id} className="col-6 col-lg-3">
                                    <h6 className='text-uppercase fw-bold'>{fMenu.WebMenu.Text}</h6>
                                    <ul className="list-unstyled">
                                        {fMenu.child.map((subMenu) => {
                                            var subMenuURL = subMenu.WebMenu.PrefixRoute + subMenu.WebMenu.Route;
                                            if (subMenu.WebMenu.TargetBlank == true) {
                                                return (
                                                    <li key={subMenu.Id} className='mb-2'><a  target='_blank' href={subMenu.WebMenu.Route}>{subMenu.WebMenu.Text}</a></li>
                                                );
                                            }
                                            else {
                                                return (
                                                    <li key={subMenu.Id} className='mb-2'><Link to={subMenu.WebMenu.PrefixRoute + subMenu.WebMenu.Route}>{subMenu.WebMenu.Text}</Link></li>
                                                );
                                            }
                                        })}
                                    </ul>
                                </div>
                            )
                        }
                        else {
                            return (socialMedias.length != 0 ?
                                <div key={fMenu.Id} className="col-6 col-lg-3">
                                    <h6 className='text-uppercase fw-bold'>{fMenu.WebMenu.Text}</h6>
                                    <ul className="list-unstyled">
                                        <li className="mb-2"><a href={`tel:${socialMedias.ContactInformation.Phone}`} >
                                            <img src="/assets/media/icons/phone.svg" className="px-2" />{socialMedias.ContactInformation.Phone}</a>
                                        </li>
                                        <li className="mb-2">
                                            <img src="/assets/media/icons/faks.svg" className="px-2" />{socialMedias.ContactInformation.Faks}
                                        </li>
                                        <li className="mb-2"><a href={`mailto:${socialMedias.ContactInformation.Email}`}><img
                                        src="/assets/media/icons/mail.svg" className="px-2" />{socialMedias.ContactInformation.Email}</a>
                                        </li>
                                        <li className="mb-2">
                                        <img src="/assets/media/icons/pin.svg" className="px-2" />{socialMedias.ContactInformation.Address}
                                        </li>
                                    </ul>
                                </div> : ''
                            )
                        }
                    })}
                </div>
            </div>
            <div className="copyright-footer">
                <div className="container p-5">
                    <div className="row justify-content-between opacity-75">
                        <div className="col-lg-6">{language.copyright}</div>
                        <div className="col-lg-4">
                            <div className="d-flex justify-content-end opacity-75">
                                {
                                    privacyMenu.length != 0 ?
                                    privacyMenu.map((pri)=>{
                                        if(pri.WebMenu.TargetBlank == true){
                                            return (
                                                <div key={pri.Id} className="p-2 text-end"><a target="_blank" href={pri.WebMenu.Route}>{pri.WebMenu.Text}</a></div>                            
                                            )
                                        }
                                        else{
                                            return (
                                                <div key={pri.Id} className="p-2 text-end"><Link to={pri.WebMenu.PrefixRoute + pri.WebMenu.Route}>{pri.WebMenu.Text}</Link></div>                            
                                            )
                                        }
                                    }) : ''
                                }    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}