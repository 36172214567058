import { useState, useEffect,useContext } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { menuAll, activityList, announcementList, newsList, projectList, eBookList, presidentDetails } from "../../services/services";
import InfoCard from '../../components/infoCard';
import LoadSpinner from '../../components/loadSpinner';
import { LangContext,langs } from '../../locales/langContext';


export default function Home() {
    const {language, setLanguage} = useContext(LangContext)
    const [isLoading, setLoading] = useState(true);
    const [insideMenus, setInsideMenus] = useState([]);
    const [homePartialMenu, setHomePartialMenu] = useState([]);
    const [newsSlider, setNewsSlider] = useState([]);
    const [activitySlider, setActivitySlider] = useState([]);
    const [announcementHome, setAnnouncementHome] = useState([]);
    const [projectSlider, setProjectSlider] = useState([]);
    const [eBookSlider, setEBookSlider] = useState([]);
    const [presidentDetail, setPresidentDetail] = useState([]);
    const [webTV,setWebTV] = useState([]);


    //Carousel 
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 5 },
    ];

    //Menu
    useEffect(() => {
        menuAll().then((response) => {
            response.data[1].WebPartialMenuViewModel.map((topMenu) => {
                if (topMenu.MenuId == 8) {
                    topMenu.child.map((childs) => {
                        setInsideMenus(childs);
                        //WebTv Text
                        if(childs.MenuId == 28){
                            setWebTV(childs.WebMenu.Text)
                        }
                    })
                }
            })

            //Home Partial Menu
            setHomePartialMenu(response.data[3].WebPartialMenuViewModel);
        })
    }, []);
    //Lists  
    useEffect(() => {
        newsList('1', '15').then((response) => {
            setNewsSlider(response.data);
            setLoading(false);
        });

        activityList('1', '10').then((response) => {
            setActivitySlider(response.data);
        })

        announcementList('1', '6').then((response) => {
            setAnnouncementHome(response.data);
        });

        projectList('1', '3').then((response) => {
            setProjectSlider(response.data);
        });

        eBookList('1', '2').then((response) => {
            setEBookSlider(response.data);
        });

        presidentDetails().then((response) => {
            setPresidentDetail(response.data);
        })

    }, []);
    

    return (
        <div>
            <main>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12 col-lg-7">
                            {
                                isLoading == true ?
                                    <LoadSpinner /> :
                                    <Carousel itemsToShow={1} className='home-carousel'>
                                        {
                                            newsSlider.map((news, index) => {
                                                if (index < 5) {
                                                    return (
                                                        <Link key={news.ID} to={news.WebUrl} className="text-decoration-none text-center">
                                                            <div>
                                                                <img src={news.MediaURL.replace('/original/', '/780/')} className='home-slider-news rounded-15' />
                                                                <div className="home-img-top-text text-light mb-4">
                                                                    <h5 className="card-title">{news.Title.length > 40 ? news.Title.substring(0, 40) + '...' : news.Title}</h5>
                                                                    <small className="card-text opacity-75">{news.NearDateLocal}</small>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    );
                                                }
                                            })}
                                    </Carousel>
                            }

                        </div>
                        <div className="col-md-12 col-lg-5">
                            <div className="card-home-menu justify-content-end">
                                {
                                    homePartialMenu.length != 0 ?
                                        homePartialMenu.map((pMenu) => {
                                            if (pMenu.WebMenu.TargetBlank) {
                                                var bgGreen = '';
                                                if(pMenu.WebMenu.Controller == 'EBelediye'){
                                                    bgGreen = 'bg-green';
                                                }
                                                return (
                                                    <div key={pMenu.Id} className={`text-center ${bgGreen}`}>
                                                        <a className='text-decoration-none text-light' target='_blank' href={pMenu.WebMenu.Route}>
                                                            <img src={pMenu.Icon} />
                                                            <p className="pt-3">{pMenu.WebMenu.Text}</p>
                                                        </a>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div key={pMenu.Id} className="text-center">
                                                    <Link className='text-decoration-none text-light' to={pMenu.WebMenu.PrefixRoute + pMenu.WebMenu.Route}>
                                                        <img src={pMenu.Icon} />
                                                        <p className="pt-3">{pMenu.WebMenu.Text}</p>
                                                    </Link>
                                                </div>

                                            )
                                        })
                                        : ''
                                }
                            </div>
                            <div className="card-home-menu-mobile text-center">
                                {
                                    homePartialMenu.length != 0 ?
                                        homePartialMenu.map((pMenu) => {
                                            if (pMenu.WebMenu.TargetBlank) {
                                                return (
                                                    <a key={pMenu.Id} className='btn btn-outline-light m-2' target='_blank' href={pMenu.WebMenu.Route}>
                                                       {pMenu.WebMenu.Text}
                                                    </a>
                                                )
                                            }
                                            return (
                                                <Link key={pMenu.Id} className='btn btn-outline-light m-2' to={pMenu.WebMenu.PrefixRoute + pMenu.WebMenu.Route}>
                                                    {pMenu.WebMenu.Text}
                                                </Link>
                                            )
                                        }) : ''
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="minister-card p-5">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-5 text-center">
                            {
                                presidentDetail.length != 0 ?
                                    <img className='img-fluid rounded-15 mb-3' src={
                                        presidentDetail.Media[0] != null ?
                                            presidentDetail.Media[0].MediaUrl: '/assets/media/background/default-image.jpeg'
                                    } alt="..." /> : ''
                            }
                        </div>
                        {
                            presidentDetail.length != 0 ?
                                <div className="col-md-5 text-center">
                                    <h1 className="text-light">{presidentDetail.Name + ' ' + presidentDetail.LastName}</h1>
                                    <h5 className="text-light fw-lighter opacity-50">{presidentDetail.Title}</h5>
                                    <div className="mt-3">
                                        {
                                            presidentDetail.MayorSocialMediaAccountsViewModel.length != 0 ?
                                                presidentDetail.MayorSocialMediaAccountsViewModel.map((socialMedia) => {
                                                    return (
                                                        <a key={socialMedia.IconUrl} target='_blank' href={socialMedia.SocialUrl} className="me-2">
                                                            <img className='bg-light p-2 rounded-3' style={{ width: '36px', height: '36px' }} src={`/assets/media/icons/${socialMedia.IconUrl}`} />
                                                        </a>
                                                    )
                                                }) : ''
                                        }
                                    </div>
                                    <div className="mt-3">
                                        <Link to={'/baskan/ozgecmis'} className="btn btn-light mx-1">Özgeçmiş</Link>
                                        <Link to={'/baskan/fotograflar'} className="btn btn-light mx-1">Fotoğraflar</Link>
                                    </div>
                                </div> : ''
                        }

                    </div>
                </div>
            </div>
            <div className="container-fluid bg-light">
                <div className="container p-5 align-items-center">
                    <ul className="nav nav-pills custom-tab justify-content-center mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link text-secondary active btn-lg p-3 px-5 rounded-pill" id="pills-home-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"
                                aria-controls="pills-home" aria-selected="true">{language.news}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link text-secondary btn-lg p-3 px-5 rounded-pill" id="pills-profile-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                                aria-controls="pills-profile" aria-selected="false">{language.activity}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link text-secondary btn-lg p-3 px-5 rounded-pill" id="pills-contact-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab"
                                aria-controls="pills-contact" aria-selected="false">{language.announcement}</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            {
                                isLoading == true ?
                                    <LoadSpinner /> :
                                    newsSlider.length != 0 ?
                                        <Carousel width='100%' itemsToShow={5} breakPoints={breakPoints} className='multi-carosuel mt-4'>
                                            {
                                                newsSlider.map((news, index) => {

                                                    if (newsSlider.length > 5 && index > 4) {
                                                        return (
                                                            <Link key={news.ID} to={news.WebUrl} className="text-dark text-decoration-none">
                                                                <div className="card c-carousel border-0 px-2 bg-transparent">
                                                                    <img src={news.MediaURL.replace('/original/', '/300/')} className="card-img-top-carousel rounded-15"
                                                                        alt="..." />
                                                                    <div className="card-body bg-opacity-100">
                                                                        <h6 className="card-title home-carousel-title">{news.Title.length > 40 ? news.Title.substring(0, 40) + '...' : news.Title}</h6>
                                                                        <small className="card-text opacity-75">{news.NearDateLocal}</small>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        );
                                                    }
                                                    if (newsSlider.length < 6) {
                                                        return (
                                                            <Link key={news.ID} to={news.WebUrl} className="text-dark text-decoration-none">
                                                                <div className="card c-carousel border-0 px-2 bg-transparent">
                                                                    <img src={news.MediaURL.replace('/original/', '/300/')} className="card-img-top-carousel rounded-15"
                                                                        alt="..." />
                                                                    <div className="card-body bg-opacity-100">
                                                                        <h6 className="card-title home-carousel-title">{news.Title.length > 40 ? news.Title.substring(0, 40) + '...' : news.Title}</h6>
                                                                        <small className="card-text opacity-75">{news.NearDateLocal}</small>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        );
                                                    }
                                                })}
                                        </Carousel> : <InfoCard />
                            }
                            <div className="text-center">
                                <Link to="/hizli-erisim/haberler" className="btn btn-green p-3 px-4 mt-5">{language.newsAll}</Link>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            {activitySlider.length != 0 ?
                                <Carousel itemsToShow={5} breakPoints={breakPoints} className='multi-carosuel mt-4'>
                                    {activitySlider.map((activitys) => {
                                        return (
                                            <Link key={activitys.ID} to={activitys.WebUrl} className="text-dark text-decoration-none">
                                                <div className="card c-carousel border-0 bg-transparent">
                                                    <img src={activitys.MediaURL.replace('/original/', '/300/')} className="card-img-top-carousel rounded-15"
                                                        alt="..." />
                                                    <div className="card-body bg-opacity-100">
                                                        <h6 className="card-title home-carousel-title">{activitys.Title.length > 40 ? activitys.Title.substring(0, 40) + '...' : activitys.Title}</h6>
                                                        <small className="card-text opacity-75">{activitys.NearDateLocal}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </Carousel> : <InfoCard />
                            }
                            <div className="text-center">
                                <Link to="/hizli-erisim/etkinlikler" className="btn btn-green p-3 px-4 mt-5">{language.activityAll}</Link>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className='row mt-5'>
                                {
                                    announcementHome.length != 0 ?
                                        announcementHome.map((announcements, index) => {
                                            return (
                                                <div key={announcements.Id} className='col-lg-6'>
                                                    <Link to={announcements.WebUrl} className="text-dark text-decoration-none">
                                                        <div className="card-side c-hover p-4 mb-3">
                                                            <img width="32" src="/assets/media/icons/announcement.svg" />
                                                            <span className="fw-bolder ms-3">{announcements.Header.length > 40 ? announcements.Header.substring(0, 40) + '...' : announcements.Header}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            );
                                        }) :
                                        <InfoCard />
                                }
                            </div>
                            <div className="text-center">
                                <Link to="/hizli-erisim/duyurular" className="btn btn-green p-3 px-4 mt-5">{language.announcementAll}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-green-op">
                <div className="container text-center p-5">
                    <h1 className="text-light">{language.project}</h1>
                    {
                        projectSlider.length != 0 ?
                            <Carousel itemsToShow={3} breakPoints={breakPoints} className='multi-carosuel mt-4'>
                                {
                                    projectSlider.map((project) => {
                                        return (
                                            <Link key={project.Id} to={project.WebUrl} className="text-decoration-none text-center">
                                                <div className="card border-0 px-2 bg-transparent">
                                                    <img src={project.MediaURL} className='card-img-top-project' />
                                                    <div className="img-top-text text-light mb-3">
                                                        <h6 className="card-title">{project.Title.length > 40 ? project.Title.substring(0, 40) + '...' : project.Title}</h6>
                                                        <small className="card-text opacity-75">{project.StartDateString}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </Carousel> :
                            <div className="alert alert-secondary border-0 py-5 text-center" role="alert">
                                <div><img className='opacity-50 mb-3' src='/assets/media/icons/infoAlert.svg' width="64" /></div>
                                <small className='opacity-75'>{language.notDataInfo}</small>
                            </div>
                    }
                    <Link to="/hizli-erisim/projeler" className="btn btn-light p-3 px-4 mt-5">{language.projectAll}</Link>
                </div>
            </div>
            <div className="container-fluid bg-dark">
                <div className="container p-5 text-center">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="text-light">{language.ebooks}</h2>
                            {eBookSlider.length != 0 ?
                                <Carousel itemsToShow={2} breakPoints={breakPoints} className='multi-carosuel mt-5'>
                                    {
                                        eBookSlider.map((eBook) => {
                                            return (
                                                <a key={eBook.Id} href={eBook.FileUrl} target='_blank' className="text-dark text-decoration-none">
                                                    <div className="card border-0 px-2 bg-transparent">
                                                        <img src={eBook.MediaUrl} className="card-img-media-top-ebooks rounded-15"
                                                            alt="..." />
                                                        <div className="card-body bg-opacity-100">
                                                            <h6 className="card-title text-light">{eBook.Title.length > 40 ? eBook.Title.substring(0, 40) + '...' : eBook.Title}</h6>
                                                            <small className="card-text opacity-75">{eBook.NearDateLocal}</small>
                                                        </div>
                                                    </div>
                                                </a>
                                            );
                                        })}
                                </Carousel> : 
                                <div className="alert alert-secondary border-0 py-5 text-center" role="alert">
                                <div><img className='opacity-50 mb-3' src='/assets/media/icons/infoAlert.svg' width="64"/></div>
                                <small className='opacity-75'>{language.notDataInfo}</small>
                            </div>
                            }
                            <Link to='/hizli-erisim/e-kitap/' className="btn btn-light p-3 px-4 mt-5 mb-4">{language.allText}</Link>
                        </div>
                        <div className="col-md-6">
                            <h2 className="text-light">{webTV != null ? webTV : ""}</h2>
                            <div className="mt-5">
                            <iframe width="100%" height="300px" src="../live.html" title="W3Schools Free Online Web Tutorials"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-light hide-h">
                <div className="container p-5 text-center">
                    <h1 className="text-dark fw-bold">{language.mobileAppTitle}</h1>
                    <p className="mb-4">{language.mobileAppSubTitle}</p>
                    <a href="#" className="btn"><img src="/assets/media/icons/appStore.svg" /></a>
                    <a href="#" className="btn"><img src="/assets/media/icons/googlePlay.svg" /></a>
                </div>
            </div>
        </div>

    )
}