import axios from "axios";

//-- Default Service Info
const baseURL = "https://yusufelipanel.kentcepte.com/api";
const appToken = "WqTOeSZL29a1zZlcm0c0l/skcx2HpuQOWfB32+CfpNA=";
const config = { headers: { 'appToken': appToken,'Content-Type': 'application/json' }};

//-- Services URL
const socialMedias = '/WebSiteSettingsAPI/GetListFullWebAPI?PageNo=1&Items=8&refLanguageId=1&Token=4e5d740f';
const menu = "/WebMenusAPI/GetAllMenuList?refLanguageId=1";
const news = "/WebNewsAPI?Token=4e5d740f&PageNo=1&Items=5&refLanguageId=1";
const newsDetail = "/WebNewsAPI?Token=4e5d740f&PageNo=1&Items=5&refLanguageId=1";
const activity = "/WebActivitiesAPI?Token=4e5d740f&PageNo=1&Items=5&refLanguageId=1";
const activitiesDetail = "/WebActivitiesApiNew?Token=4e5d740f&PageNo=1&Items=5&refLanguageId=1";
const announcement = "/WebAnnouncementAPI?Token=4e5d740f&PageNo=1&Items=5&refLanguageId=1";
const announcementsDetail = "/WebAnnouncementAPI/Get?Token=4e5d740f&Id=44&refLanguageId=1";
const formerPresident = "/FormerMayorAPI/GetList?PageNo=1&Items=5&refLanguageId=1";
const presidentDetail = "/WebMayorAPI/Get?Token=4e5d740f&refLanguageId=1";
const presidentMessagee = '/WebMayorAPI/PostPresidentMessage';
const parliamentaryAgendaLists = "/WebParliamentaryAPI/GetAgendaOfParliamentary?PageNo=1&Items=5&refLanguageId=1&Token=4e5d740f";
const parliamentaryMembers = "/WebParliamentaryAPI/GetParliamentaryMember?PageNo=1&Items=5&refLanguageId=1&Token=4e5d740f";
const incomeExpenseBudget = '/StrategicManagementAPI/GetIncomeExpenditureBudget?Token=4e5d740f&refLanguageId=1';
const dinamicDescriptionPage = '/WebMenusAPI/GetDescriptionPagesDetay?refLanguageId=1&Route=kurumsal-alt-sayfasi';
const dinamicImagePage = '/WebMenusAPI/GetPictureListPagesDetay?refLanguageId=1&Route=kurumsal-alt-sayfasi';
const projects = '/ProjectsAPI/GetList?PageNo=1&Items=5&refLanguageId=1';
const projectDetail = '/ProjectsAPI/GetDetail?refLanguageId=1&Id=1';
const eBook = '/EBooksApi/GetEBooksList?PageNo=1&Items=5&refLanguageId=2';
const travelGuide = '/TravelGuideAPI/GetList?PageNo=1&Items=5&refLanguageId=1&Token=4e5d740f';
const travelDetail = '/TravelGuideAPI/GetDetail?Id=5&refLanguageId=1';
const galleryLists = '/WebPhotoGalleryAPI/Gallery?Token=4e5d740f&PublishType=1';
const dinamicFiles = '/WebMenusAPI/GetFileListPagesDetay?refLanguageId=1&Route=dosya-liste';
const socialServiceLists = '/SocialServiceAPI/GetList?PageNo=1&Items=5&refLanguageId=1';
const socialServiceDetails = '/SocialServiceAPI/GetDetail?Id=25&refLanguageId=1';
const wishMessa = '/WebWishRequest/PostWebWishRequest';
const contacts = '/WebContactAPI/PostWebContactRequest';
const searchs = '/WebGeneralSearchAPI/GetGeneralSearchWeb?keyword=proje&refLanguageId=1&ModuleId=0&PageNo=1&Items=100';
const helpContact = '/HelpContactAPI/GetList?PageNo=1&Items=5&refLanguageId=1'
const guestBook= '/GuestbookAPI/GetGuestbook?refLanguageId=1';

export async function ipAdress(pageNo,items) {
    return await axios.get('https://geolocation-db.com/json/');
}

export async function socialMedia(pageNo,items,lang) {
    return await axios.get(baseURL + `/WebSiteSettingsAPI/GetListFullWebAPI?Token=4e5d740f&PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function menuAll(lang) {
    return await axios.get(baseURL + `/WebMenusAPI/GetAllMenuList?refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function newsList(pageNo,items,lang) {
    return await axios.get(baseURL + `/WebNewsAPI?Token=4e5d740f&PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function newDetail(id,lang) {
    return await axios.get(baseURL + `/WebNewsAPI/GetList?Token=4e5d740f&Id=${id}&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function activityList(pageNo,items,lang) {
    return await axios.get(baseURL + `/WebActivitiesAPI?Token=4e5d740f&PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function activityDetail(id,lang) {
    return await axios.get(baseURL + `/WebActivitiesApiNew/GetDetail?Token=4e5d740f&Id=${id}&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function announcementList(pageNo,items,lang) {
    return await axios.get(baseURL + `/WebAnnouncementAPI/GetList?Token=4e5d740f&PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function announcementDetail(id,lang) {
    return await axios.get(baseURL + `/WebAnnouncementAPI/Get?Token=4e5d740f&Id=${id}&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function formerPresidentList(pageNo,items,lang) {
    return await axios.get(baseURL + `/FormerMayorAPI/GetList?PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function presidentMessages(data) {
    return await axios.post(baseURL + `/WebMayorAPI/PostPresidentMessage`,data, config);
}

export async function wishMessages(data) {
    return await axios.post(baseURL + `/WebWishRequest/PostWebWishRequest`,data, config);
}

export async function presidentDetails(lang) {
    return await axios.get(baseURL + `/WebMayorAPI/Get?Token=4e5d740f&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function parliamentaryAgendaList(pageNo,items,type,lang) {
    return await axios.get(baseURL + `/WebParliamentaryAPI/GetAgendaOfParliamentary?Token=4e5d740f&PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}&type=${type}`);
}

export async function parliamentaryMemberList(pageNo,items,lang) {
    return await axios.get(baseURL + `/WebParliamentaryAPI/GetParliamentaryMember?Token=4e5d740f&PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function incomeExpenseBudgetList(lang) {
    return await axios.get(baseURL + `/StrategicManagementAPI/GetIncomeExpenditureBudget?Token=4e5d740f&refLanguageId=${lang == null ? 1 : lang}`);
}

export async function projectList(pageNo,items,lang) {
    return await axios.get(baseURL + `/ProjectsAPI/GetList?PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function projectDetails(id,lang) {
    return await axios.get(baseURL + `/ProjectsAPI/GetDetail?refLanguageId=${lang == null ? 1 : lang}&Id=${id}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function eBookList(pageNo,items,lang) {
    return await axios.get(baseURL + `/EBooksApi/GetEBooksList?PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function eBookDetail(id,lang) {
    return await axios.get(baseURL + `/EBooksApi/GetEBooksDetail?Id=${id}&refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function travelGuideList(pageNo,items,lang) {
    return await axios.get(baseURL + `/TravelGuideAPI/GetList?Token=4e5d740f&PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function travelDetails(id,lang) {
    return await axios.get(baseURL + `/TravelGuideAPI/GetDetail?Id=${id}&refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function galleryList() {
    return await axios.get(baseURL + `/WebPhotoGalleryAPI/Gallery?Token=4e5d740f&PublishType=1`);
}

export async function socialServiceList(pageNo,items,lang) {
    return await axios.get(baseURL + `/SocialServiceAPI/GetList?PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function socialServiceDetail(id,lang) {
    return await axios.get(baseURL + `/SocialServiceAPI/GetDetail?Id=${id}&refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function contactForm(data) {
    return await axios.post(baseURL + `/WebContactAPI/PostWebContactRequest`,data, config);
}

export async function searchList(keyWord,pageNo,items,lang) {
    return await axios.get(baseURL + `/WebGeneralSearchAPI/GetGeneralSearchWeb?keyword=${keyWord}&refLanguageId=${lang == null ? 1 : lang}&ModuleId=0&PageNo=${pageNo}&Items=${items}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function helpContacts(pageNo,items,lang) {
    return await axios.get(baseURL + `/HelpContactAPI/GetList?PageNo=${pageNo}&Items=${items}&refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}

export async function guestBooks(lang) {
    return await axios.get(baseURL + `/GuestbookAPI/GetGuestbook?refLanguageId=${lang == null ? 1 : lang}`,{
        headers: { 
            'appToken': appToken
          }
    });
}
export async function guestBookForm(data) {
    return await axios.post(baseURL + `/GuestbookAPI/PostGuestbook`,data, config);
}

//-- Dinamics Pages
export async function dinamicDescriptionPages(pageName,lang){
    return await axios.get(baseURL + `/WebMenusAPI/GetDescriptionPagesDetay?refLanguageId=${lang == null ? 1 : lang}&Route=${pageName}`,{
        headers: { 
            'appToken': appToken
          }
    })
}

export async function dinamicImagesPages(pageName,lang){
    return await axios.get(baseURL + `/WebMenusAPI/GetPictureListPagesDetay?refLanguageId=${lang == null ? 1 : lang}&Route=${pageName}`,{
        headers: { 
            'appToken': appToken
          }
    })
}

export async function dinamicFile(pageName,lang){
    return await axios.get(baseURL + `/WebMenusAPI/GetFileListPagesDetay?refLanguageId=${lang == null ? 1 : lang}&Route=${pageName}`,{
        headers: { 
            'appToken': appToken
          }
    })
}

