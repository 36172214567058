import { useState, useEffect } from 'react';
import { Link,useLocation } from "react-router-dom";
import InstitutionalMenu from '../../components/institutionalMenu';
import President from '../../components/president';
import { parliamentaryAgendaList } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';


export default function ParliamentaryDecisions() {
    const [parliamentaryAgendaLists, setParliamentaryAgendaLists] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;

    useEffect(() => {
        parliamentaryAgendaList(1, 100,1).then((response) => {
            setParliamentaryAgendaLists(response.data);
            setLoading(false);
        })
    }, []);

    return (
        <div>
            <BreadCrump/>
            <div className="container-fluid bg-light">
                <div className="container py-5">
                <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu/>
                            <President/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            <div className='row'>
                            {
                                isLoading == true ?
                                <LoadSpinner/> :
                                parliamentaryAgendaLists.length != 0 ?
                                parliamentaryAgendaLists.map((parliamentaryAgenda)=>{
                                        return (
                                                <Link key={parliamentaryAgenda.Id} to={activeUrl + '/' + parliamentaryAgenda.Id} className="text-dark text-decoration-none">
                                                <div key={parliamentaryAgenda.Id} className="card-side c-hover p-4 mb-3">
                                                <img width="32" src="/assets/media/icons/announcement.svg" />
                                                <span className="fw-bolder ms-3">{parliamentaryAgenda.Title.length > 100 ? parliamentaryAgenda.Title.substring(0,100) + '...' : parliamentaryAgenda.Title}</span>
                                                </div>
                                                </Link>
                                            );  
                                }): 
                                <InfoCard/>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}