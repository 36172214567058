import { useState,useEffect,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AccessMenu from '../../components/accessMenu';
import BreadCrump from '../../components/breadCrump';
import InfoCard from '../../components/infoCard';
import LoadSpinner from '../../components/loadSpinner';
import President from '../../components/president';
import { newsList } from "../../services/services";
import { LangContext,langs } from '../../locales/langContext';


export default function News(){
    const {language, setLanguage} = useContext(LangContext);
    const [newsAllList,setNewsAllList] = useState([]); 
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const [pageCount,setPageCount] = useState(15);
    const [btnDisabled,setBtnDisabled] = useState(false);

    useEffect(() => {
      newsList('1',pageCount,language.languNumber).then((response)=>{
        setNewsAllList(response.data);
        setLoading(false);
        if(response.data.length != 0){
            if(response.data[0].TotalItem <= pageCount){
                setBtnDisabled(true);
            }
            else{
                setBtnDisabled(false);
            }
        }
        else{
            setBtnDisabled(true);
        }

    })},[language]);

    const loadData = () => {
        var pageItem = pageCount + 9
        setPageCount(pageItem)
        newsList('1',pageItem).then((response)=>{
            setNewsAllList(response.data);
            setLoading(false);
            if (newsAllList[0].TotalItem == response.data.length) {
                setBtnDisabled(true);
              }
              else {
                setBtnDisabled(false);
              }
        });
    }
 

return (
    <div>
       <BreadCrump/>
    <div className="container-fluid bg-light">
        <div className="container py-5">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                    <AccessMenu/>
                    <President/>
                </div>
                <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                    <div className="row">
                    {
                    isLoading == true ?
                    <LoadSpinner/> : 
                    newsAllList.length != 0 ?
                    newsAllList.map((newsList)=>{
                        return (
                            <div key={newsList.ID} className="col-md-12 col-lg-6 col-xl-4 mb-3">
                            <Link to={activeUrl + '/' + newsList.ID} className="text-dark text-decoration-none">
                                <div className="card custom-cards border-0 px-2 mb-3 bg-transparent">
                                    <img src={newsList.MediaURL} className="card-img-top rounded-15"
                                        alt="..."/>
                                    <div className="card-body bg-opacity-100">
                                        <h6 className="card-title custom-card-title">{newsList.Title.length > 40 ? newsList.Title.substring(0,40) + '...' : newsList.Title}</h6>
                                        <div className="d-flex justify-content-between mt-">
                                            {newsList.Categories.map((categories,index)=>{
                                                if(index < 1){
                                                    return (<span key={newsList.ID} className="c-badge text-dark text-start h-25">{categories.length > 6 ? categories.substring(0,6) + '...' : categories}</span>)
                                                }
                                            })}
                                            <small className="card-text opacity-75">{newsList.NearDateLocal}</small>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        );
                    }):
                    <InfoCard/>
                    }  
                    </div>
                    <div className='text-center'><button onClick={loadData} disabled={btnDisabled} className='btn btn-green p-3 px-4'>{language.more}</button></div>
                </div>  
            </div>    
        </div>
    </div>
    </div>
)
}