import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import Carousel from 'react-elastic-carousel';
import AccessMenu from '../../components/accessMenu';
import President from '../../components/president';
import { activityDetail } from "../../services/services";
import InfoCard from '../../components/infoCard';
import LoadSpinner from '../../components/loadSpinner';
import FsLightbox from 'fslightbox-react';
import BreadCrump from '../../components/breadCrump';

export default function ActivitiesDetail() {
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const urlId = activeUrl.split("/")[3];
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    //Carousel 
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 }
    ];

    const [activitiesDetails, setActivitiesDetails] = useState([]);
    useEffect(() => {
        activityDetail(urlId).then((response) => {
            setActivitiesDetails(response.data);
            console.log(response.data)
            setLoading(false);
        })
    }, []);

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }




    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <AccessMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                            {
                                isLoading == true ?
                                    <LoadSpinner /> :
                                    activitiesDetails.length != 0 ?
                                        <div>
                                            {activitiesDetails.Categories.map((detailCategories) => {
                                                return (<span key={detailCategories.CategoriesID} className="c-badge text-dark text-start me-3">{detailCategories}</span>)
                                            })}
                                            <small className="card-text opacity-75">{activitiesDetails.NearDateLocal}</small>
                                            <h2 className="my-3">{activitiesDetails.Title}</h2>
                                            <img src={activitiesDetails.MediaURL.replace('/original/', '/780/')} className="detail-img rounded-15 mb-4" alt="..." />
                                            <p className='fw-bold'>{activitiesDetails.Summary}</p>
                                            {activitiesDetails.Detail!= null ? activitiesDetails.Detail.split("\n").map((line, idx) => <p key={idx}>{line}</p>) : ''}
                                            <div className="card-side text-center mt-4 p-5">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 col-xl-3 mb-4">
                                                        <p className="opacity-50">Başlangıç - Bitiş</p>
                                                        <p className="fw-bolder">
                                                            {
                                                                <div>
                                                                    <p style={{fontSize:'14px'}}>{activitiesDetails.Date[0].DateLocal}</p>
                                                                    <p style={{fontSize:'14px'}}>{activitiesDetails.Date[activitiesDetails.Date.length - 1].DateLocal}</p>
                                                                </div>
                                                            }
                                                        </p>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-light p-3">Tüm Tarih ve Saatler</a>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-3 mb-4">
                                                        <p className="opacity-50">Bilet Fiyatı</p>
                                                        <p className="fw-bolder">{activitiesDetails.Price}</p>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-3 mb-4">
                                                        <p className="opacity-50">Katılım Koşulu</p>
                                                        <p className="fw-bolder">{activitiesDetails.Condition}</p>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-3 mb-4">
                                                        <p className="opacity-50">Düzenleyen</p>
                                                        <p className="fw-bolder">{activitiesDetails.Arranger}</p>
                                                    </div>
                                                    <div className="col-12 col-lg-6 col-xl-3 mb-4">
                                                        <p className="opacity-50">Telefon</p>
                                                        <p className="fw-bolder">{activitiesDetails.ContactGsmNumber}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {

                                                activitiesDetails.OtherMedias != 0 ?
                                                    <div>
                                                        <Carousel itemsToShow={3} breakPoints={breakPoints} className='multi-carosuel mt-5'>
                                                            {
                                                                activitiesDetails.OtherMedias.map((mediasUrls, index) => {
                                                                    return (
                                                                        <div onClick={() => openLightboxOnSlide(index + 1)} key={`media-${index}`} className="card bg-transparent border-0 px-2">
                                                                            <img className="img-fluid card-img-top-media" src={mediasUrls.replace('/original/', '/300/')} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Carousel>
                                                        <FsLightbox
                                                            toggler={lightboxController.toggler}
                                                            sources={activitiesDetails.OtherMedias}
                                                            slide={lightboxController.slide}
                                                        />
                                                    </div> : ''
                                            }
                                        </div>
                                        : <InfoCard />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Tarih ve Saatler</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ height: '40vh' }}>
                            <ol className="list-group list-group-numbered">
                                {
                                    activitiesDetails.length != 0 ?
                                        activitiesDetails.Date.map((dates) => {
                                            return (
                                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{dates.DateLocal}</div>
                                                        {
                                                            dates.Seances.length != 0 ?
                                                                dates.Seances.map((seance) => {
                                                                    return (
                                                                        <p className="c-badge text-dark text-start me-2">{seance}</p>
                                                                    )
                                                                }) : ''
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        }) : ''
                                }
                            </ol>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}