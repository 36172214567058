import React, { useState, useEffect,useContext } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './partials/footer';
import Header from './partials/header';
import Activities from './views/activities/activities';
import ActivitiesDetail from './views/activities/activitiesDetail';
import Announcement from './views/announcement/announcement';
import AnnouncementsDetail from './views/announcement/announcementDetail';
import Contact from './views/contact/contact';
import DescriptionDetail from './views/dinamics/descriptions';
import ErrorPage from './views/error/errorPage';
import Home from './views/home/home';
import FormerPresident from './views/institutional/formerPresident';
import IncomeExpenseBudget from './views/institutional/incomeExpenseBudget';
import ParliamentaryAgenda from './views/institutional/parliamentaryAgenda';
import ParliamentaryAgendaDetail from './views/institutional/parliamentaryAgendaDetail';
import ParliamentaryMember from './views/institutional/parliamentaryMember';
import ParliamentaryMemberDetail from './views/institutional/parliamentaryMemberDetail';
import NewsDetail from './views/news/detail';
import News from './views/news/news';
import PresidentDetail from './views/president/presidentDetail';
import PresidentMessage from './views/president/presidentMessage';
import ProjectDetail from './views/projects/projectDetail';
import Project from './views/projects/projects';
import { menuAll } from './services/services';
import ImageListPage from './views/dinamics/imageListPage';
import Travel from './views/travel/travel';
import TravelDetail from './views/travel/travelDetail';
import Gallery from './views/yusufeli/gallery';
import GalleryDetail from './views/yusufeli/galleryDetail';
import FilePage from './views/dinamics/filePage';
import SocialServices from './views/socialServices/socialServices';
import SocialServicesDetail from './views/socialServices/socailServicesDetail';
import PresidentGallery from './views/president/presidentGallery';
import PresidentGalleryDetail from './views/president/presidentGalleryDetail';
import WishMessage from './views/wishComplaint/wishMessage';
import FormerPresidentDetail from './views/institutional/formerPresidentDetail';
import EBookList from './views/e-books/eBooks';
import WebTV from './views/webTV/webTv';
import Kvvk from './views/agreement/kvkk';
import Privacy from './views/agreement/privacy';
import VisitorBook from './views/visitorBook/visitorBook';
import HelpContactNumber from './views/institutional/helpContacts';
import { LangContext,langs } from './locales/langContext';
import ParliamentaryDecisions from './views/institutional/parliamentaryDecisions';
import ParliamentaryDecisionsDetail from './views/institutional/parliamentaryDecisionsDetail';


function App() {
  const selectedLangu = localStorage.getItem('LANGU-OS-91');
  const [language,setLanguage] = useState(selectedLangu == "en" ? langs.en : langs.tr);
  const contextValue = useContext(LangContext)

  const x = []
  const urlLocations = useLocation();
  const activeUrls = urlLocations.pathname;

  const [dinamics, setDinamics] = useState([]);
  const [dinamicsImage, setDinamicsImage] = useState([]);
  const [dinamicsFile, setDinamicsFile] = useState([]);
  const [allMenu, setAllMenu] = useState([]);
  const [insideMenu, setInsideMenu] = useState([]);
  const [privacyMenu, setPrivacyMenu] = useState([]);
  useEffect(() => {
    menuAll().then((response) => {
      setAllMenu(response.data[2].WebPartialMenuViewModel);
      setInsideMenu(response.data[1].WebPartialMenuViewModel);
      setPrivacyMenu(response.data[4].WebPartialMenuViewModel);
    })
  }, []);


  useEffect(() => {
    allMenu.map((topMenu) => {
     
      //Childs
      if (topMenu.child != 0) {
        topMenu.child.map((childs) => {
          var prefixRoute = childs.WebMenu.PrefixRoute + childs.WebMenu.Route;
          if (childs.WebMenu.ModuleId == 77 && prefixRoute == activeUrls) {
            setDinamics(childs.WebMenu.PrefixRoute + childs.WebMenu.Route);
          }
          else if (childs.WebMenu.ModuleId == 78 && prefixRoute == activeUrls) {
            setDinamicsImage(childs.WebMenu.PrefixRoute + childs.WebMenu.Route);
          }
          else if (childs.WebMenu.ModuleId == 79 && prefixRoute == activeUrls) {
            setDinamicsFile(childs.WebMenu.PrefixRoute + childs.WebMenu.Route);
          }
        });
      }
    });

    insideMenu.map((topMenu) => {
      //Childs
      if (topMenu.child != 0) {
        topMenu.child.map((childs) => {
          var prefixRoute = childs.WebMenu.PrefixRoute + childs.WebMenu.Route;
          if (childs.WebMenu.ModuleId == 77 && prefixRoute == activeUrls) {
            setDinamics(childs.WebMenu.PrefixRoute + childs.WebMenu.Route);
          }
          else if (childs.WebMenu.ModuleId == 78 && prefixRoute == activeUrls) {
            setDinamicsImage(childs.WebMenu.PrefixRoute + childs.WebMenu.Route);
          }
          else if (childs.WebMenu.ModuleId == 79 && prefixRoute == activeUrls) {
            setDinamicsFile(childs.WebMenu.PrefixRoute + childs.WebMenu.Route);
          }
        });
      }
    });

    privacyMenu.map((priMenu) => {
      //Childs
      if (priMenu.child != 0) {
        priMenu.child.map((childs) => {
          var prefixRoute = childs.WebMenu.PrefixRoute + childs.WebMenu.Route;
          if (childs.WebMenu.ModuleId == 77 && prefixRoute == activeUrls) {
            setDinamics(childs.WebMenu.PrefixRoute + childs.WebMenu.Route);
          }
          else if (childs.WebMenu.ModuleId == 78 && prefixRoute == activeUrls) {
            setDinamicsImage(childs.WebMenu.PrefixRoute + childs.WebMenu.Route);
          }
          else if (childs.WebMenu.ModuleId == 79 && prefixRoute == activeUrls) {
            setDinamicsFile(childs.WebMenu.PrefixRoute + childs.WebMenu.Route);
          }
        });
      }
    });
  });

  const imagesList = [
    'url("https://images.dog.ceo/breeds/labrador/n02099712_3503.jpg")',
    'url("https://images.dog.ceo/breeds/labrador/n02099712_5844.jpg")',
    'url("https://images.dog.ceo/breeds/labrador/n02099712_5343.jpg")',
    'url("https://images.dog.ceo/breeds/labrador/n02099712_7481.jpg")',
    'url("https://images.dog.ceo/breeds/labrador/n02099712_7414.jpg")'
  ];

  return (
    <div className="App">
      <LangContext.Provider value={{language,setLanguage}}>
      <Header />
      <Routes>
        <Route path="*" exact element={<ErrorPage />} />
        <Route path="/" exact element={<Home />} />
        <Route path="/hizli-erisim/haberler" element={<News />} />
        <Route path="/hizli-erisim/haberler/:id" element={<NewsDetail />} />
        <Route path="/hizli-erisim/etkinlikler" element={<Activities />} />
        <Route path="/hizli-erisim/etkinlikler/:id" element={<ActivitiesDetail />} />
        <Route path="/hizli-erisim/duyurular" element={<Announcement />} />
        <Route path="/hizli-erisim/duyurular/:id" element={<AnnouncementsDetail />} />
        <Route path="/hizli-erisim/projeler" element={<Project />} />
        <Route path="/hizli-erisim/dilek-sikayet" element={<WishMessage />} />
        <Route path="/hizli-erisim/projeler/:id" element={<ProjectDetail />} />
        <Route path="/hizli-erisim/e-kitap" element={<EBookList/>} />
        <Route path="/hizli-erisim/yusufeli-belediyesi-web-tv" element={<WebTV/>} />
        <Route path="/hizli-erisim/ziyaretci-defteri" element={<VisitorBook/>} />
        <Route path="/kurumsal/eski-baskanlar" element={<FormerPresident />} />
        <Route path="/kurumsal/eski-baskanlar/:id" element={<FormerPresidentDetail />} />
        <Route path="/kurumsal/meclis-gundemi" element={<ParliamentaryAgenda />} />
        <Route path="/kurumsal/meclis-gundemi/:id" element={<ParliamentaryAgendaDetail />} />
        <Route path="/kurumsal/meclis-kararlari" element={<ParliamentaryDecisions/>} />
        <Route path="/kurumsal/meclis-kararlari/:id" element={<ParliamentaryDecisionsDetail/>} />
        <Route path="/kurumsal/meclis-uyeleri" element={<ParliamentaryMember />} />
        <Route path="/kurumsal/meclis-uyeleri/:id" element={<ParliamentaryMemberDetail />} />
        <Route path="/kurumsal/gelir-gider-butceleri" element={<IncomeExpenseBudget />} />
        <Route path="/kurumsal/kurum-telefonlari" element={<HelpContactNumber/>} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/baskan/ozgecmis" element={<PresidentDetail />} />
        <Route path="/baskan/baskana-mesaj" element={<PresidentMessage />} />
        <Route path="/baskan/fotograflar" element={<PresidentGallery />} />
        <Route path="/baskan/fotograflar/:id" element={<PresidentGalleryDetail />} />
        <Route path="/yusufeli/gezi-rehberi" element={<Travel />} />
        <Route path="/yusufeli/gezi-rehberi/:id" element={<TravelDetail />} />
        <Route path="/yusufeli/galeri/" element={<Gallery />} />
        <Route path="/yusufeli/galeri/:id" element={<GalleryDetail />} />
        <Route path="/yusufeli/sosyal-hizmetler/" element={<SocialServices />} />
        <Route path="/yusufeli/sosyal-hizmetler/:id" element={<SocialServicesDetail />} />
        <Route path={dinamics.length != 0 ? dinamics : '/'} element={<DescriptionDetail />} />
        <Route path={dinamicsImage.length != 0 ? dinamicsImage : '/'} element={<ImageListPage />} />
        <Route path={dinamicsFile.length != 0 ? dinamicsFile : '/'} element={<FilePage />} />
      </Routes>
      <Footer />
        </LangContext.Provider>
    </div>
  );
}

export default App;
