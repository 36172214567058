import { useState, useEffect,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { menuAll } from '../services/services';
import LoadSpinner from './loadSpinner';
import { LangContext,langs } from '../locales/langContext';


export default function AccessMenu() {
    const {language, setLanguage} = useContext(LangContext);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;

    const [accessMenus, setAccessMenus] = useState([]);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        menuAll().then((response) => {
            setAccessMenus(response.data[1].WebPartialMenuViewModel[1].child);
            setLoading(false);
        })
    }, []);

    return (
        <div className="card-side p-5">
            <h4 className="fw-bold pb-3">{language.menu}</h4>
            <div className="list-group list-group-flush">
                {
                    isLoading == true ?
                        <LoadSpinner /> :
                        accessMenus.map((menus, index) => {
                            var cActive = "";
                            var apiRoute = menus.WebMenu.PrefixRoute + menus.WebMenu.Route
                            if (activeUrl == apiRoute) {
                                cActive = "c-active disabled";
                            }
                            else {
                                cActive = "bg-transparent";
                            }
                            if(menus.WebMenu.TargetBlank != true){
                                return (
                                    <Link key={menus.Id} to={menus.WebMenu.PrefixRoute + menus.WebMenu.Route} className={`list-group-item list-group-item-action py-3 ${cActive}`}>{menus.WebMenu.Text}</Link>
                                );
                            }
                            else{
                                return (
                                    <a key={menus.Id} target='_blank' href={menus.WebMenu.Route} className={`list-group-item list-group-item-action py-3 bg-transparent`}>{menus.WebMenu.Text}</a>
                                );
                            }


                        })}
            </div>
        </div>
    );
}