import { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import InstitutionalMenu from '../../components/institutionalMenu';
import President from '../../components/president';
import { dinamicFile } from "../../services/services";
import InfoCard from '../../components/infoCard';
import BreadCrump from '../../components/breadCrump';
import LoadSpinner from '../../components/loadSpinner';


export default function FilePage() {
    const [file, setFile] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const urlLocation = useLocation();
    const activeUrl = urlLocation.pathname;
    const urlPageName = activeUrl.split("/")[2];

    useEffect(() => {
        dinamicFile(activeUrl).then((response) => {
            setFile(response.data);
            setLoading(false);
        });
    }, []);

    return (
        <div>
            <BreadCrump />
            <div className="container-fluid bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                            <InstitutionalMenu />
                            <President />
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-9 m-t-space">
                        <div className='row'>
                            {
                            
                                isLoading == true ?
                                <LoadSpinner/> :
                                file.length != 0 ?
                                file.WebPictureList.map((fileItem)=>{
                                        return (
                                                <a key={fileItem.Id} target='_blank' href={fileItem.FileUrl} className="text-dark text-decoration-none">
                                                <div key={fileItem.Id} className="card-side c-hover p-4 mb-3">
                                                <img width="32" src="/assets/media/icons/fileDark.svg"/>
                                                <span className="fw-bolder ms-3">{fileItem.Title.substring(0,100)}</span>
                                                </div>
                                                </a>
                                            );  
                                }): 
                                <InfoCard/>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}